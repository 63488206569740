import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Amplify } from 'aws-amplify';
//@ts-ignore
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
// eslint-disable-next-line
import App from './App';
import * as serviceWorker from './serviceWorker';
import awsConfig from './awsConfig';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: awsConfig.cognito.REGION,
    userPoolId: awsConfig.cognito.USER_POOL_ID,
    identityPoolId: awsConfig.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: awsConfig.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'emilog',
        endpoint: awsConfig.apiGateway.URL,
        region: awsConfig.apiGateway.REGION,
      },
      {
        name: 'csvLambda',
        endpoint: awsConfig.csvLambda.URL,
        service: 'lambda',
        region: awsConfig.apiGateway.REGION,
      },
    ],
  },
});

Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: awsConfig.cognito.REGION,
  aws_pubsub_endpoint: 'wss://a2cg0yo8jbddsk-ats.iot.eu-central-1.amazonaws.com/mqtt',
}));

// window.LOG_LEVEL = 'VERBOSE';

const container = document.getElementById('root');
ReactDOM.createRoot(container!).render(<App />);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
