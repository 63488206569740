import { createTheme } from '@material-ui/core';
import { deDE } from '@material-ui/core/locale';
import dinFont from './assets/fonts/DIN2014-Regular.woff';
import dinFontBold from './assets/fonts/DIN2014-Bold.woff';

const din2014 = {
  fontFamily: 'DIN2014',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('DIN2014'),
    local('DIN2014-Regular'),
    url(${dinFont}) format('woff')
  `,
};

const din2014Bold = {
  fontFamily: 'DIN2014',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('DIN2014'),
    local('DIN2014-Bold'),
    url(${dinFontBold}) format('woff')
  `,
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#014a69',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: [
      'DIN2014',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        // @ts-ignore
        '@font-face': [din2014, din2014Bold],
        '& input::-webkit-inner-spin-button, & input::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
          display: 'none',
        },
      },
    },
  },
}, deDE);

export default theme;
