import React, { useContext } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import AuthContext from '../context/AuthContext';

import DashboardTable from '../components/Dashboard/DashboardTable';

export default function Dashboard() {
  const { currentUser } = useContext(AuthContext);

  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {(currentUser.user === null) ? (
        <div style={{ marginTop: 32 }}>
          <Skeleton style={{ marginBottom: 10 }} variant="rect" />
          <Skeleton style={{ marginBottom: 10 }} variant="rect" />
          <Skeleton variant="rect" />
        </div>
      ) : (
        <DashboardTable />
      )}
    </>
  );
}
