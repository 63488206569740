/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import '../../styles/shadow_svg_style.css';
import { EmiconShadow } from '../../types/ShadowGraphic';
import { roundShadowValue } from '../../utils/roundShadowValue';
import convertTimestamp from '../../utils/convertTimestamp';
// import { EmiconShadow } from '../../../../types/Shadow';

interface ShadowGraphicProps {
  serialNumber: string
  isEmicon: boolean
  reported: Partial<EmiconShadow>
  loaded: boolean
  reportedTimestamp: number | null
}


export default function ShadowGraphic({ serialNumber, isEmicon, reported, loaded, reportedTimestamp }: ShadowGraphicProps) {

  return (
    loaded ? (
      <Card style={{ marginTop: 32 }}>
        <CardContent style={{ padding: '16px' }}>
          <svg width="99%" id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1140.95 530.23">
            <defs />
            <rect className="cls-5" x="31.61" y="1.8" width="147.06" height="47.81" rx="6.96" ry="6.96" />
            <rect className="cls-5" x="283.88" y="16.08" width="145.88" height="58.59" rx="6.96" ry="6.96" />
            <g>
              <path className="cls-8" d="m300.59,40.54v-15.87h5.06c3.52,0,5.75,1.98,5.75,7.11v1.66c0,5.13-2.23,7.11-5.75,7.11h-5.06Zm8.83-7.11v-1.66c0-3.93-1.33-5.31-3.77-5.31h-3.08v12.28h3.08c2.44,0,3.77-1.38,3.77-5.31Z" />
              <path className="cls-8" d="m320.27,31.52c-.48-.67-1.08-.97-1.93-.97-1.4,0-2.46,1.06-2.46,2.78v7.2h-1.84v-11.5h1.84v1.13c.48-.81,1.26-1.31,2.67-1.31,1.56,0,2.55.53,3.29,1.56l-1.56,1.1Z" />
              <path className="cls-8" d="m332.21,40.54h-1.84v-1.13c-.58.87-1.38,1.31-3.08,1.31-2.46,0-4.19-1.29-4.19-4.51v-7.17h1.84v7.15c0,2.32,1.26,2.83,2.62,2.83,1.72,0,2.81-1.22,2.81-2.81v-7.17h1.84v11.5Z" />
              <path className="cls-8" d="m343.57,38.79c-.71,1.33-2.12,1.93-3.98,1.93-3.15,0-4.97-2.02-4.97-5.2v-1.52c0-3.27,1.86-5.15,4.97-5.15,1.72,0,3.15.62,3.98,1.79l-1.45,1.01c-.51-.74-1.47-1.15-2.53-1.15-1.98,0-3.13,1.33-3.13,3.5v1.52c0,1.98.92,3.54,3.13,3.54,1.08,0,2.07-.37,2.55-1.29l1.43,1.01Z" />
              <path className="cls-8" d="m354.29,29.04l-4.97,5.08,5.68,6.42h-2.39l-4.65-5.01-.71.76v4.25h-1.84v-15.87h1.84v9.06l4.65-4.69h2.39Z" />
              <path className="cls-8" d="m361.56,33.06h5.96v1.66h-5.96v-1.66Z" />
              <path className="cls-8" d="m377.89,38.74h7.59v1.79h-9.57v-15.87h1.98v14.07Z" />
              <path className="cls-8" d="m396.08,40.54h-1.84v-1.13c-.58.87-1.38,1.31-3.08,1.31-2.46,0-4.19-1.29-4.19-4.51v-7.17h1.84v7.15c0,2.32,1.26,2.83,2.62,2.83,1.72,0,2.81-1.22,2.81-2.81v-7.17h1.84v11.5Z" />
              <path className="cls-8" d="m399.53,29.04v-.83c0-2.39.78-3.54,3.5-3.54h.53v1.75h-.46c-1.54,0-1.72.67-1.72,2.25v.37h2.18v1.66h-2.18v9.84h-1.84v-9.84h-1.47v-1.66h1.47Z" />
              <path className="cls-8" d="m404.61,29.04h1.47v-2.53h1.84v2.53h2.64v1.66h-2.64v5.84c0,1.59.18,2.25,1.72,2.25h.92v1.75h-.99c-2.71,0-3.5-1.15-3.5-3.54v-6.3h-1.47v-1.66Z" />
            </g>
            <g>
              <path className="cls-8" d="m362.1,54.57h1.84v1.13c.51-.78,1.38-1.31,2.85-1.31s2.71.62,3.36,1.82c.78-1.22,2.05-1.82,3.45-1.82,2.46,0,3.96,1.52,3.96,4.51v7.17h-1.84v-7.15c0-2.09-1.04-2.83-2.39-2.83-1.47,0-2.58,1.17-2.58,2.81v7.17h-1.84v-7.15c0-2.09-1.03-2.83-2.39-2.83-1.49,0-2.58,1.22-2.58,2.81v7.17h-1.84v-11.5Z" />
              <path className="cls-8" d="m382.39,55.72c.62-.85,1.61-1.33,3.13-1.33,2.85,0,4.6,1.86,4.6,5.15v1.52c0,2.99-1.47,5.2-4.6,5.2-1.54,0-2.51-.39-3.13-1.2v1.01h-1.84v-15.87h1.84v5.52Zm5.89,5.33v-1.52c0-2.18-1.06-3.5-2.94-3.5s-2.94,1.26-2.94,3.33v1.68c0,1.98.83,3.54,2.94,3.54,1.95,0,2.94-1.59,2.94-3.54Z" />
              <path className="cls-8" d="m396.25,59.43h2.74v-1.33c0-1.63-.76-2-2.55-2-1.38,0-2.12.12-2.76,1.17l-1.38-.97c.8-1.33,1.98-1.91,4.16-1.91,2.92,0,4.37.94,4.37,3.7v7.98h-1.84v-1.13c-.64,1.06-1.43,1.31-2.92,1.31-2.67,0-4.12-1.31-4.12-3.54,0-2.48,1.77-3.29,4.3-3.29Zm2.74,1.56h-2.74c-1.82,0-2.46.62-2.46,1.77,0,1.29.92,1.79,2.28,1.79,1.7,0,2.92-.32,2.92-2.9v-.67Z" />
              <path className="cls-8" d="m410.05,57.06c-.48-.67-1.08-.97-1.93-.97-1.4,0-2.46,1.06-2.46,2.78v7.2h-1.84v-11.5h1.84v1.13c.48-.81,1.26-1.31,2.67-1.31,1.56,0,2.55.53,3.29,1.56l-1.56,1.1Z" />
            </g>
            <rect className="cls-5" x="62.39" y="157.96" width="160.08" height="86.47" rx="6.96" ry="6.96" />
            <g>
              <path className="cls-8" d="m92.53,167.2v15.87h-1.93l-6.85-12v12h-1.98v-15.87h1.89l6.9,12.12v-12.12h1.98Z" />
              <path className="cls-8" d="m101.39,183.25c-3.52,0-5.75-2.12-5.75-7.24v-1.75c0-5.13,2.23-7.24,5.75-7.24s5.75,2.12,5.75,7.24v1.75c0,5.13-2.23,7.24-5.75,7.24Zm-3.77-7.24c0,3.93,1.33,5.45,3.77,5.45s3.77-1.52,3.77-5.45v-1.75c0-3.93-1.33-5.45-3.77-5.45s-3.77,1.52-3.77,5.45v1.75Z" />
              <path className="cls-8" d="m136.56,167.2v1.33l-7.77,12.74h8v1.79h-10.55v-1.33l7.75-12.74h-7.75v-1.79h10.32Z" />
              <path className="cls-8" d="m139.2,167.2h2.07v2.07h-2.07v-2.07Zm.12,4.37h1.84v11.5h-1.84v-11.5Z" />
              <path className="cls-8" d="m152.86,181.32c-.8,1.4-2.02,1.93-4.02,1.93-3.08,0-4.92-2-4.92-5.2v-1.52c0-3.27,1.54-5.15,4.55-5.15s4.55,1.86,4.55,5.15v1.2h-7.27v.32c0,1.98.94,3.54,2.94,3.54,1.49,0,2.25-.44,2.74-1.29l1.43,1.01Zm-7.11-5.2h5.4c-.09-1.93-.94-3.08-2.69-3.08s-2.6,1.17-2.71,3.08Z" />
              <path className="cls-8" d="m155.72,167.2h1.84v11.87c0,1.24.07,2.3,1.95,2.3v1.7c-2.9,0-3.79-1.15-3.79-3.54v-12.33Z" />
              <path className="cls-8" d="m165.7,183.06h-1.61l-3.33-11.5h1.95l2.3,8.07,2.9-8.07h1.54l3.06,8.14,2.3-8.14h1.95l-3.33,11.5h-1.61l-3.06-8.6-3.06,8.6Z" />
              <path className="cls-8" d="m186.86,181.32c-.8,1.4-2.02,1.93-4.02,1.93-3.08,0-4.92-2-4.92-5.2v-1.52c0-3.27,1.54-5.15,4.55-5.15s4.55,1.86,4.55,5.15v1.2h-7.27v.32c0,1.98.94,3.54,2.94,3.54,1.49,0,2.25-.44,2.74-1.29l1.43,1.01Zm-7.11-5.2h5.4c-.09-1.93-.94-3.08-2.69-3.08s-2.6,1.17-2.71,3.08Z" />
              <path className="cls-8" d="m195.94,174.05c-.48-.67-1.08-.97-1.93-.97-1.4,0-2.46,1.06-2.46,2.78v7.2h-1.84v-11.5h1.84v1.13c.48-.81,1.26-1.31,2.67-1.31,1.56,0,2.55.53,3.29,1.56l-1.56,1.1Z" />
              <path className="cls-8" d="m197.76,171.57h1.47v-2.53h1.84v2.53h2.64v1.66h-2.64v5.84c0,1.59.18,2.25,1.72,2.25h.92v1.75h-.99c-2.71,0-3.5-1.15-3.5-3.54v-6.3h-1.47v-1.66Z" />
            </g>
            <g>
              <path className="cls-8" d="m124.49,197.41h1.84v1.13c.51-.78,1.38-1.31,2.85-1.31s2.71.62,3.36,1.82c.78-1.22,2.05-1.82,3.45-1.82,2.46,0,3.96,1.52,3.96,4.51v7.17h-1.84v-7.15c0-2.09-1.04-2.83-2.39-2.83-1.47,0-2.58,1.17-2.58,2.81v7.17h-1.84v-7.15c0-2.09-1.03-2.83-2.39-2.83-1.49,0-2.58,1.22-2.58,2.81v7.17h-1.84v-11.5Z" />
              <path className="cls-8" d="m150.08,197.41h1.84v11.27c0,3.2-1.84,4.74-4.92,4.74-2,0-3.22-.53-4.02-1.93l1.43-1.01c.48.85,1.24,1.29,2.74,1.29,2,0,2.94-1.1,2.94-3.08v-.78c-.62.8-1.61,1.2-3.13,1.2-3.13,0-4.6-2.21-4.6-5.2v-1.52c0-3.29,1.75-5.15,4.6-5.15,1.52,0,2.51.48,3.13,1.33v-1.15Zm0,6.48v-1.52c0-2.16-1.06-3.5-2.94-3.5s-2.94,1.31-2.94,3.5v1.52c0,1.95.99,3.54,2.94,3.54,2.12,0,2.94-1.56,2.94-3.54Z" />
              <path className="cls-8" d="m154.5,211.57l7.73-21.34h1.77l-7.73,21.34h-1.77Z" />
              <path className="cls-8" d="m177.29,193.04v15.87h-1.93l-6.85-12v12h-1.98v-15.87h1.89l6.9,12.12v-12.12h1.98Z" />
              <path className="cls-8" d="m180.74,197.41h1.84v1.13c.51-.78,1.38-1.31,2.85-1.31s2.71.62,3.36,1.82c.78-1.22,2.05-1.82,3.45-1.82,2.46,0,3.96,1.52,3.96,4.51v7.17h-1.84v-7.15c0-2.09-1.04-2.83-2.39-2.83-1.47,0-2.58,1.17-2.58,2.81v7.17h-1.84v-7.15c0-2.09-1.03-2.83-2.39-2.83-1.49,0-2.58,1.22-2.58,2.81v7.17h-1.84v-11.5Z" />
              <path className="cls-8" d="m200.18,195.98h.44c.87,0,1.36-.32,1.36-.99,0-.57-.41-.87-.99-.87-.67,0-1.03.34-1.13.87h-1.24c0-1.15,1.03-2.02,2.37-2.02s2.21.71,2.21,1.93c0,.85-.53,1.33-1.2,1.63.78.21,1.33.85,1.33,1.72,0,1.36-.99,2.12-2.35,2.12-1.49,0-2.39-.97-2.46-2.07h1.27c.09.53.46.92,1.2.92.6,0,1.1-.3,1.1-1.01,0-.62-.39-1.08-1.47-1.08h-.44v-1.15Z" />
            </g>
            <g>
              <path className="cls-8" transform="translate(-15, 0)" d="m106.81,228.71c0,1.84.8,2.18,1.63,2.18,1.52,0,2-1.43,2-3.36,0-5.59-3.98-7.75-7.57-7.75-5.27,0-7.57,4.14-7.57,7.75,0,5.08,3.66,7.73,7.68,7.73,1.89,0,3.4-.32,5.06-1.15l.71,1.31c-.64.55-2.92,1.49-5.89,1.49-4.67,0-9.4-3.29-9.4-9.38s4.53-9.4,9.4-9.4,9.4,3.43,9.4,9.4c0,2.83-1.17,5.01-3.89,5.01-1.17,0-2.28-.51-2.78-1.59-.46,1.06-1.36,1.59-2.97,1.59-2.62,0-3.86-1.86-3.86-4.37v-1.2c0-2.78,1.45-4.35,3.86-4.35,1.06,0,1.79.28,2.32.76v-.64h1.84v5.96Zm-1.84-.62v-1.26c0-1.68-.74-2.53-2.16-2.53-1.52,0-2.18.92-2.18,2.78v1.01c0,1.7.62,2.81,2.18,2.81,1.7,0,2.16-1.1,2.16-2.81Z" />
              <path className="cls-8" d="m143.41,222.39c0,2.07-.99,3.61-3.33,3.61-2.09,0-3.29-1.38-3.29-3.61v-.99c0-2.23,1.24-3.56,3.29-3.56s3.33,1.31,3.33,3.56v.99Zm-1.45-.02v-.92c0-1.43-.64-2.21-1.89-2.21-1.17,0-1.82.81-1.82,2.21v.92c0,1.29.46,2.25,1.82,2.25s1.89-.99,1.89-2.25Zm7.91-4.35l-8.85,15.87h-1.93l8.88-15.87h1.91Zm2.55,12.44c0,2.07-.99,3.61-3.33,3.61-2.09,0-3.29-1.38-3.29-3.61v-.99c0-2.23,1.24-3.56,3.29-3.56s3.33,1.31,3.33,3.56v.99Zm-1.45-.02v-.92c0-1.43-.64-2.21-1.89-2.21-1.17,0-1.82.8-1.82,2.21v.92c0,1.29.46,2.25,1.82,2.25s1.89-.99,1.89-2.25Z" />
              <path className="cls-8" d="m165.53,234.07c-3.52,0-5.75-2.12-5.75-7.24v-1.75c0-5.13,2.23-7.24,5.75-7.24s5.75,2.12,5.75,7.24v1.75c0,5.13-2.23,7.24-5.75,7.24Zm-3.77-7.24c0,3.93,1.33,5.45,3.77,5.45s3.77-1.52,3.77-5.45v-1.75c0-3.93-1.33-5.45-3.77-5.45s-3.77,1.52-3.77,5.45v1.75Z" />
            </g>
            <circle className="cls-5" cx="222.46" cy="99.02" r="30.71" />
            <path className="cls-2" d="m201.52,76.32c16.75,4.52,33.51,9.04,50.26,13.56" />
            <path className="cls-2" d="m199.82,119.76c17.49-4.63,34.98-9.25,52.47-13.88" />
            <path className="cls-8" d="m251.25,89.38s0,.05,0,.08,0,.05.01.08c0,0,0,0,0,.01.01.03.03.07.04.1,0,0,0,.02.02.03.02.04.06.08.1.11.04.03.08.06.13.07.05.02.1.02.15,0,.05,0,.1-.02.14-.04l.08-.06s.08-.1.1-.17v-.1c.01-.07,0-.13-.04-.19,0-.01-.01-.02-.02-.03l.04.09s0-.03-.01-.04v.1s.01-.02.01-.03c0-.05,0-.1-.03-.14-.02-.05-.04-.09-.08-.12-.03-.04-.07-.06-.12-.08-.04-.02-.09-.03-.14-.03h-.1c-.06.03-.12.06-.17.11l-.06.08c-.03.06-.05.12-.05.19h0Z" />
            <path className="cls-8" d="m251.38,89.44h.01c-.09-.04-.18-.07-.27-.11l-.04-.05-.04-.09c.04.09.07.19.1.29.03.1.07.21.1.31.07.2.13.4.2.6.06.19.26.33.46.26.19-.06.33-.26.26-.46-.08-.23-.15-.46-.23-.7-.04-.11-.08-.23-.11-.34-.02-.05-.04-.11-.06-.16-.03-.08-.05-.13-.12-.19-.08-.08-.17-.1-.28-.11-.09,0-.2.05-.27.11s-.11.17-.11.27.04.2.11.27.16.1.27.11h0Z" />
            <path className="cls-8" d="m251.97,105.55s0,.09,0,.14v-.1c-.01.19-.07.37-.11.56s-.09.38-.13.57.06.42.26.46c.19.04.41-.06.46-.26.05-.22.1-.43.15-.65.05-.23.13-.47.12-.71,0-.2-.17-.38-.38-.38-.2,0-.38.17-.38.38h0Z" />
            <path className="cls-8" d="m252.44,106.83c.03-.14.07-.27.12-.4l-.04.09c.03-.06.06-.13.09-.19.03-.04.04-.09.04-.14.01-.05,0-.1,0-.15-.02-.09-.09-.18-.17-.22-.09-.05-.19-.07-.29-.04-.09.03-.18.09-.22.17-.11.22-.2.44-.25.68-.02.05-.02.1,0,.15,0,.05.02.1.04.14.05.08.13.15.22.17.1.02.2.02.29-.04.08-.05.15-.13.17-.22h0Z" />
            <path className="cls-8" d="m201.74,75.77c-.05.04-.11.08-.16.12s-.1.08-.14.13c-.08.11-.16.22-.23.33-.03.04-.04.09-.04.14-.01.05,0,.1,0,.15.01.05.03.09.07.13.03.04.06.07.11.1.09.05.19.07.29.04l.09-.04c.06-.03.1-.08.13-.13.06-.08.11-.17.17-.25l-.06.08s.07-.07.11-.1l-.08.06s.08-.06.12-.09c.04-.02.08-.06.11-.1.03-.04.06-.08.07-.13.02-.05.02-.1,0-.15,0-.05-.02-.1-.04-.14-.05-.08-.13-.15-.22-.17h-.1c-.07-.01-.13,0-.19.04h0Z" />
            <path className="cls-8" d="m201.86,76.9c.02-.05.05-.1.09-.14l-.06.08c.08-.1.18-.19.28-.27l-.08.06c.27-.21.56-.39.73-.69.1-.17.05-.42-.13-.51s-.41-.05-.51.13c-.02.04-.05.08-.08.12l.06-.08c-.08.1-.18.19-.28.27l.08-.06c-.27.21-.58.4-.73.72-.04.09-.07.19-.04.29.02.09.09.18.17.22.09.05.19.07.29.04.09-.03.18-.09.22-.17h0Z" />
            <path className="cls-8" d="m202.36,75.48c-.13.08-.25.16-.37.26-.04.03-.06.07-.08.12-.02.04-.03.09-.03.14,0,.05,0,.1.03.14.02.05.04.09.08.12l.08.06c.06.03.12.05.19.05h.1c.06-.03.12-.06.17-.11,0,0,.01-.01.02-.02l-.08.06c.09-.07.18-.13.27-.18.04-.02.08-.06.11-.1.03-.04.06-.08.07-.13.02-.05.02-.1,0-.15,0-.05-.02-.1-.04-.14l-.06-.08s-.1-.08-.17-.1h-.1c-.07-.01-.13,0-.19.04h0Z" />
            <path className="cls-8" d="m199.94,119.2h0s.06.1.1.16h0c-.09.15-.17.3-.26.45h.01s-.07,0-.1,0c0,0,.02,0,.03,0h-.1s0-.01.01,0l-.09-.04s.02,0,.02.01l-.08-.06s.03.02.04.04l-.06-.08c.07.09.14.19.22.27.06.07.12.13.18.19.13.14.24.29.36.44.06.07.18.11.27.11.09,0,.2-.04.27-.11s.11-.17.11-.27v-.1c-.03-.06-.06-.12-.11-.17-.11-.13-.21-.27-.32-.4-.05-.06-.11-.11-.16-.17-.06-.06-.11-.12-.16-.19l.06.08c-.05-.06-.09-.13-.14-.18-.12-.11-.28-.16-.44-.11-.09.02-.18.09-.22.17-.05.09-.06.18-.04.29.01.08.06.12.1.18.06.07.18.11.27.11.09,0,.2-.04.27-.11s.11-.17.11-.27v-.1c-.03-.06-.06-.12-.11-.17h0Z" />
            <path className="cls-8" d="m200.71,120.01l-.04-.02s-.09-.04-.14-.04c-.05-.01-.1,0-.15,0l-.09.04s-.08.06-.11.1c-.02.02-.04.05-.05.08-.02.04-.03.09-.03.14v.1c.03.06.06.12.11.17,0,0,0,0,0,0,.01.01.03.02.04.03.01.01.03.02.04.03.02,0,.03.02.05.02.02,0,.03.01.05.02.02,0,.04,0,.05,0,.02,0,.04,0,.05,0h.1s0-.01,0-.02l.09-.04s.05-.04.08-.06c.02-.02.05-.05.06-.08.04-.07.04-.08.05-.14s.01-.08,0-.15-.03-.08-.07-.13c-.02-.02-.02-.03-.03-.04-.02-.02-.05-.04-.08-.06-.02-.01-.03-.02-.05-.03-.1-.06-.2-.1-.3-.14-.05-.02-.1-.02-.15,0-.05,0-.1.02-.14.04-.04.02-.08.06-.11.1-.03.04-.06.08-.07.13v.1c-.01.07,0,.13.04.19l.06.08s.1.08.17.1c.02,0,.04.01.06.02l-.09-.04c.08.03.15.07.22.12l-.08-.06s0,0,0,0l-.06-.08h0s-.03-.06-.04-.09h0s0-.06-.01-.1h0s0-.06.01-.1h0s.03-.06.04-.09h0s.04-.05.06-.07h0s.05-.04.08-.06h0s.06-.02.09-.04c0,0,0,0,0,0h.1s0-.01,0-.01h.1s0,.01,0,.01l.09.04s0,0,0,0l.08.06s0,0-.01-.01l-.45.59.04.02s.09.04.14.04c.05.01.1,0,.15,0,.05-.01.09-.03.13-.07.04-.03.07-.06.1-.11l.04-.09c.02-.07.02-.13,0-.2l-.04-.09c-.03-.06-.08-.1-.13-.13h0Z" />
            <path className="cls-8" d="m200.37,120.56c.48,0,.48-.75,0-.75s-.48.75,0,.75h0Z" />
            <path className="cls-8" d="m200.39,120.57c.48,0,.48-.75,0-.75s-.48.75,0,.75h0Z" />
            <rect className="cls-5" x="34.11" y="362.93" width="144.73" height="61.39" rx="6.96" ry="6.96" />
            <g>
              <path className="cls-8" d="m68.26,381.94l4.74-9.89h1.95v15.87h-1.98v-11.63l-3.86,8.32h-1.77l-3.79-8.26v11.57h-1.98v-15.87h1.93l4.76,9.89Z" />
              <path className="cls-8" d="m87.39,382.9c0,2.99-1.47,5.2-4.78,5.2-3.06,0-4.78-2.02-4.78-5.2v-1.52c0-3.27,1.77-5.15,4.78-5.15s4.78,1.86,4.78,5.15v1.52Zm-1.84,0v-1.52c0-2.18-1.06-3.5-2.94-3.5s-2.94,1.33-2.94,3.5v1.52c0,1.98.83,3.54,2.94,3.54,1.95,0,2.94-1.59,2.94-3.54Z" />
              <path className="cls-8" d="m88.8,376.42h1.47v-2.53h1.84v2.53h2.64v1.66h-2.64v5.84c0,1.59.18,2.25,1.73,2.25h.92v1.75h-.99c-2.71,0-3.5-1.15-3.5-3.54v-6.3h-1.47v-1.66Z" />
              <path className="cls-8" d="m106.05,382.9c0,2.99-1.47,5.2-4.78,5.2-3.06,0-4.78-2.02-4.78-5.2v-1.52c0-3.27,1.77-5.15,4.78-5.15s4.78,1.86,4.78,5.15v1.52Zm-1.84,0v-1.52c0-2.18-1.06-3.5-2.94-3.5s-2.94,1.33-2.94,3.5v1.52c0,1.98.83,3.54,2.94,3.54,1.95,0,2.94-1.59,2.94-3.54Z" />
              <path className="cls-8" d="m114.69,378.9c-.48-.67-1.08-.97-1.93-.97-1.4,0-2.46,1.06-2.46,2.78v7.2h-1.84v-11.5h1.84v1.13c.48-.81,1.26-1.31,2.67-1.31,1.56,0,2.55.53,3.29,1.56l-1.56,1.1Z" />
              <path className="cls-8" d="m117.52,372.05h1.84v11.87c0,1.24.07,2.3,1.95,2.3v1.7c-2.9,0-3.79-1.15-3.79-3.54v-12.33Z" />
              <path className="cls-8" d="m127.55,381.27h2.74v-1.33c0-1.63-.76-2-2.55-2-1.38,0-2.12.12-2.76,1.17l-1.38-.97c.8-1.33,1.98-1.91,4.16-1.91,2.92,0,4.37.94,4.37,3.7v7.98h-1.84v-1.13c-.64,1.06-1.43,1.31-2.92,1.31-2.67,0-4.12-1.31-4.12-3.54,0-2.48,1.77-3.29,4.3-3.29Zm2.74,1.56h-2.74c-1.82,0-2.46.62-2.46,1.77,0,1.29.92,1.79,2.28,1.79,1.7,0,2.92-.32,2.92-2.9v-.67Z" />
              <path className="cls-8" d="m141.58,379.11c-.62-.8-1.26-1.17-2.85-1.17-1.36,0-2.23.53-2.23,1.54,0,1.31,1.15,1.7,2.67,1.82,2.16.16,4.14.78,4.14,3.43,0,2-1.36,3.38-4.32,3.38-2.35,0-3.56-.71-4.44-1.91l1.36-.97c.62.83,1.45,1.17,3.1,1.17,1.54,0,2.46-.6,2.46-1.63,0-1.24-.74-1.68-2.97-1.84-1.93-.14-3.84-.92-3.84-3.43,0-1.91,1.38-3.27,4.12-3.27,1.86,0,3.29.48,4.19,1.91l-1.38.97Z" />
              <path className="cls-8" d="m144.83,376.42h1.47v-2.53h1.84v2.53h2.64v1.66h-2.64v5.84c0,1.59.18,2.25,1.72,2.25h.92v1.75h-.99c-2.71,0-3.5-1.15-3.5-3.54v-6.3h-1.47v-1.66Z" />
            </g>
            <path className="cls-8" d="m139.6,406.8c0,2.07-.99,3.61-3.33,3.61-2.09,0-3.29-1.38-3.29-3.61v-.99c0-2.23,1.24-3.56,3.29-3.56s3.33,1.31,3.33,3.56v.99Zm-1.45-.02v-.92c0-1.43-.64-2.21-1.89-2.21-1.17,0-1.82.81-1.82,2.21v.92c0,1.29.46,2.25,1.82,2.25s1.89-.99,1.89-2.25Zm7.91-4.35l-8.85,15.87h-1.93l8.88-15.87h1.91Zm2.55,12.44c0,2.07-.99,3.61-3.33,3.61-2.09,0-3.29-1.38-3.29-3.61v-.99c0-2.23,1.24-3.56,3.29-3.56s3.33,1.31,3.33,3.56v.99Zm-1.45-.02v-.92c0-1.43-.64-2.21-1.89-2.21-1.17,0-1.82.8-1.82,2.21v.92c0,1.29.46,2.25,1.82,2.25s1.89-.99,1.89-2.25Z" />
            <rect className="cls-5" x=".5" y="497.12" width="214.79" height="32.61" />
            <rect className="cls-5" x="217.54" y="497.12" width="138.92" height="32.61" />
            <rect className="cls-6" x="208.87" y="365.9" width="142.41" height="58.42" />
            <g>
              <path className="cls-8" d="m259.34,396.45l4.74-9.89h1.95v15.87h-1.98v-11.63l-3.86,8.32h-1.77l-3.79-8.26v11.57h-1.98v-15.87h1.93l4.76,9.89Z" />
              <path className="cls-8" d="m278.48,397.42c0,2.99-1.47,5.2-4.78,5.2-3.06,0-4.78-2.02-4.78-5.2v-1.52c0-3.27,1.77-5.15,4.78-5.15s4.78,1.86,4.78,5.15v1.52Zm-1.84,0v-1.52c0-2.18-1.06-3.5-2.94-3.5s-2.94,1.33-2.94,3.5v1.52c0,1.98.83,3.54,2.94,3.54,1.95,0,2.94-1.59,2.94-3.54Z" />
              <path className="cls-8" d="m279.88,390.93h1.47v-2.53h1.84v2.53h2.64v1.66h-2.64v5.84c0,1.59.18,2.25,1.73,2.25h.92v1.75h-.99c-2.71,0-3.5-1.15-3.5-3.54v-6.3h-1.47v-1.66Z" />
              <path className="cls-8" d="m297.13,397.42c0,2.99-1.47,5.2-4.78,5.2-3.06,0-4.78-2.02-4.78-5.2v-1.52c0-3.27,1.77-5.15,4.78-5.15s4.78,1.86,4.78,5.15v1.52Zm-1.84,0v-1.52c0-2.18-1.06-3.5-2.94-3.5s-2.94,1.33-2.94,3.5v1.52c0,1.98.83,3.54,2.94,3.54,1.95,0,2.94-1.59,2.94-3.54Z" />
              <path className="cls-8" d="m305.78,393.42c-.48-.67-1.08-.97-1.93-.97-1.4,0-2.46,1.06-2.46,2.78v7.2h-1.84v-11.5h1.84v1.13c.48-.81,1.26-1.31,2.67-1.31,1.56,0,2.55.53,3.29,1.56l-1.56,1.1Z" />
            </g>
            <rect className="cls-6" x="619.38" y="365.45" width="144.15" height="58.42" />
            <g>
              <path className="cls-8" d="m682.14,390.27c-.85-1.1-1.77-1.91-3.77-1.91-1.84,0-3.08.83-3.08,2.48,0,1.89,1.43,2.53,3.75,2.94,3.06.53,4.92,1.63,4.92,4.58,0,2.07-1.38,4.44-5.33,4.44-2.58,0-4.46-.87-5.66-2.6l1.63-1.13c1.03,1.22,1.95,1.93,4.02,1.93,2.6,0,3.36-1.2,3.36-2.55,0-1.49-.76-2.51-3.75-2.97s-4.92-1.89-4.92-4.65c0-2.28,1.5-4.28,5.13-4.28,2.53,0,4.12.85,5.24,2.6l-1.54,1.1Z" />
              <path className="cls-8" d="m695.73,391.69c-.55-2.48-1.82-3.33-3.77-3.33-2.44,0-3.77,1.52-3.77,5.45v1.75c0,3.93,1.33,5.45,3.77,5.45,2.07,0,3.24-1.01,3.77-3.33h1.91c-.51,3.56-2.67,5.13-5.68,5.13-3.52,0-5.75-2.12-5.75-7.24v-1.75c0-5.13,2.23-7.24,5.75-7.24,3.13,0,5.2,1.66,5.7,5.13h-1.93Z" />
              <path className="cls-8" d="m709.35,402.61l-3.31-6.69h-3.75v6.69h-1.98v-15.87h5.06c3.66,0,5.77,1.52,5.77,4.6,0,2.23-1.1,3.63-3.13,4.23l3.54,7.04h-2.21Zm-3.98-8.49c2.69,0,3.79-.83,3.79-2.78s-1.1-2.8-3.79-2.8h-3.08v5.59h3.08Z" />
            </g>
            <rect className="cls-6" x="351.29" y="386.56" width="268.09" height="15.87" />
            <rect className="cls-6" x="763.52" y="386.73" width="370.82" height="15.87" />
            <rect className="cls-5" x="363.5" y="422.58" width="157.2" height="86.88" rx="6.96" ry="6.96" />
            <g>
              <path className="cls-8" d="m427.07,428.67v15.87h-1.93l-6.85-12v12h-1.98v-15.87h1.89l6.9,12.12v-12.12h1.98Z" />
              <path className="cls-8" d="m435.92,444.72c-3.52,0-5.75-2.12-5.75-7.24v-1.75c0-5.13,2.23-7.24,5.75-7.24s5.75,2.12,5.75,7.24v1.75c0,5.13-2.23,7.24-5.75,7.24Zm-3.77-7.24c0,3.93,1.33,5.45,3.77,5.45s3.77-1.52,3.77-5.45v-1.75c0-3.93-1.33-5.45-3.77-5.45s-3.77,1.52-3.77,5.45v1.75Z" />
            </g>
            <g>
              <path className="cls-8" d="m431.79,460.45h1.84v1.13c.51-.78,1.38-1.31,2.85-1.31s2.71.62,3.36,1.82c.78-1.22,2.05-1.82,3.45-1.82,2.46,0,3.96,1.52,3.96,4.51v7.17h-1.84v-7.15c0-2.09-1.04-2.83-2.39-2.83-1.47,0-2.58,1.17-2.58,2.81v7.17h-1.84v-7.15c0-2.09-1.03-2.83-2.39-2.83-1.49,0-2.58,1.22-2.58,2.81v7.17h-1.84v-11.5Z" />
              <path className="cls-8" d="m457.39,460.45h1.84v11.27c0,3.2-1.84,4.74-4.92,4.74-2,0-3.22-.53-4.02-1.93l1.43-1.01c.48.85,1.24,1.29,2.74,1.29,2,0,2.94-1.1,2.94-3.08v-.78c-.62.8-1.61,1.2-3.13,1.2-3.13,0-4.6-2.21-4.6-5.2v-1.52c0-3.29,1.75-5.15,4.6-5.15,1.52,0,2.51.48,3.13,1.33v-1.15Zm0,6.48v-1.52c0-2.16-1.06-3.5-2.94-3.5s-2.94,1.31-2.94,3.5v1.52c0,1.96.99,3.54,2.94,3.54,2.12,0,2.94-1.56,2.94-3.54Z" />
              <path className="cls-8" d="m461.81,474.61l7.73-21.34h1.77l-7.73,21.34h-1.77Z" />
              <path className="cls-8" d="m484.6,456.08v15.87h-1.93l-6.85-12v12h-1.98v-15.87h1.89l6.9,12.12v-12.12h1.98Z" />
              <path className="cls-8" d="m488.05,460.45h1.84v1.13c.51-.78,1.38-1.31,2.85-1.31s2.71.62,3.36,1.82c.78-1.22,2.05-1.82,3.45-1.82,2.46,0,3.96,1.52,3.96,4.51v7.17h-1.84v-7.15c0-2.09-1.04-2.83-2.39-2.83-1.47,0-2.58,1.17-2.58,2.81v7.17h-1.84v-7.15c0-2.09-1.03-2.83-2.39-2.83-1.49,0-2.58,1.22-2.58,2.81v7.17h-1.84v-11.5Z" />
              <path className="cls-8" d="m507.48,459.02h.44c.87,0,1.36-.32,1.36-.99,0-.57-.41-.87-.99-.87-.67,0-1.03.34-1.13.87h-1.24c0-1.15,1.03-2.02,2.37-2.02s2.21.71,2.21,1.93c0,.85-.53,1.33-1.2,1.63.78.21,1.33.85,1.33,1.72,0,1.36-.99,2.12-2.35,2.12-1.49,0-2.39-.97-2.46-2.07h1.27c.09.53.46.92,1.2.92.6,0,1.1-.3,1.1-1.01,0-.62-.39-1.08-1.47-1.08h-.44v-1.15Z" />
            </g>
            <g>
              <path className="cls-8" transform="translate(-15, 0)" d="m401.93,494.4c0,1.84.8,2.18,1.63,2.18,1.52,0,2-1.43,2-3.36,0-5.59-3.98-7.75-7.57-7.75-5.27,0-7.57,4.14-7.57,7.75,0,5.08,3.66,7.73,7.68,7.73,1.89,0,3.4-.32,5.06-1.15l.71,1.31c-.64.55-2.92,1.5-5.89,1.5-4.67,0-9.4-3.29-9.4-9.38s4.53-9.41,9.4-9.41,9.4,3.43,9.4,9.41c0,2.83-1.17,5.01-3.89,5.01-1.17,0-2.28-.51-2.78-1.59-.46,1.06-1.36,1.59-2.97,1.59-2.62,0-3.86-1.86-3.86-4.37v-1.2c0-2.78,1.45-4.35,3.86-4.35,1.06,0,1.79.28,2.32.76v-.64h1.84v5.96Zm-1.84-.62v-1.26c0-1.68-.74-2.53-2.16-2.53-1.52,0-2.18.92-2.18,2.78v1.01c0,1.7.62,2.81,2.18,2.81,1.7,0,2.16-1.1,2.16-2.81Z" />
              <path className="cls-8" d="m438.53,488.08c0,2.07-.99,3.61-3.33,3.61-2.09,0-3.29-1.38-3.29-3.61v-.99c0-2.23,1.24-3.56,3.29-3.56s3.33,1.31,3.33,3.56v.99Zm-1.45-.02v-.92c0-1.43-.64-2.21-1.89-2.21-1.17,0-1.82.81-1.82,2.21v.92c0,1.29.46,2.25,1.82,2.25s1.89-.99,1.89-2.25Zm7.91-4.35l-8.85,15.87h-1.93l8.88-15.87h1.91Zm2.55,12.44c0,2.07-.99,3.61-3.33,3.61-2.09,0-3.29-1.38-3.29-3.61v-.99c0-2.23,1.24-3.56,3.29-3.56s3.33,1.31,3.33,3.56v.99Zm-1.45-.02v-.92c0-1.43-.64-2.21-1.89-2.21-1.17,0-1.82.8-1.82,2.21v.92c0,1.29.46,2.25,1.82,2.25s1.89-.99,1.89-2.25Z" />
              <path className="cls-8" d="m460.65,499.76c-3.52,0-5.75-2.12-5.75-7.24v-1.75c0-5.13,2.23-7.24,5.75-7.24s5.75,2.12,5.75,7.24v1.75c0,5.13-2.23,7.24-5.75,7.24Zm-3.77-7.24c0,3.93,1.33,5.45,3.77,5.45s3.77-1.52,3.77-5.45v-1.75c0-3.93-1.33-5.45-3.77-5.45s-3.77,1.52-3.77,5.45v1.75Z" />
            </g>
            <rect className="cls-5" x="363.5" y="304.32" width="143.55" height="58.61" rx="6.96" ry="6.96" />
            <g>
              <path className="cls-8" d="m446.5,341.61c0,2.07-.99,3.61-3.33,3.61-2.09,0-3.29-1.38-3.29-3.61v-.99c0-2.23,1.24-3.56,3.29-3.56s3.33,1.31,3.33,3.56v.99Zm-1.45-.02v-.92c0-1.43-.64-2.21-1.89-2.21-1.17,0-1.82.8-1.82,2.21v.92c0,1.29.46,2.25,1.82,2.25s1.89-.99,1.89-2.25Zm7.91-4.35l-8.85,15.87h-1.93l8.88-15.87h1.91Zm2.55,12.44c0,2.07-.99,3.61-3.33,3.61-2.09,0-3.29-1.38-3.29-3.61v-.99c0-2.23,1.24-3.56,3.29-3.56s3.33,1.31,3.33,3.56v.99Zm-1.45-.02v-.92c0-1.43-.64-2.21-1.89-2.21-1.17,0-1.82.8-1.82,2.21v.92c0,1.29.46,2.25,1.82,2.25s1.89-.99,1.89-2.25Z" />
              <path className="cls-8" d="m470.38,337.25h1.84v15.87h-1.84v-1.01c-.62.8-1.61,1.2-3.13,1.2-3.13,0-4.6-2.21-4.6-5.2v-1.52c0-3.29,1.75-5.15,4.6-5.15,1.52,0,2.51.48,3.13,1.33v-5.52Zm0,11.01v-1.68c0-2.16-1.06-3.5-2.94-3.5s-2.94,1.31-2.94,3.5v1.52c0,1.96.99,3.54,2.94,3.54s2.94-1.47,2.94-3.38Z" />
              <path className="cls-8" d="m481.44,344.1c-.48-.67-1.08-.97-1.93-.97-1.4,0-2.46,1.06-2.46,2.78v7.2h-1.84v-11.5h1.84v1.13c.48-.81,1.26-1.31,2.67-1.31,1.56,0,2.55.53,3.29,1.56l-1.56,1.1Z" />
              <path className="cls-8" d="m487.4,353.23l-4.51-11.61h1.98l3.43,8.92,3.01-8.92h1.95l-4.05,11.87c-.94,2.76-1.56,4-4.78,4v-1.79c1.52,0,2.23-.25,2.92-2.3l.05-.16Z" />
            </g>
            <path className="cls-8" d="m420.67,328.6c-3.52,0-5.75-2.12-5.75-7.24v-1.75c0-5.13,2.23-7.24,5.75-7.24s5.75,2.12,5.75,7.24v1.75c0,5.13-2.23,7.24-5.75,7.24Zm-3.77-7.24c0,3.93,1.33,5.45,3.77,5.45s3.77-1.52,3.77-5.45v-1.75c0-3.93-1.33-5.45-3.77-5.45s-3.77,1.52-3.77,5.45v1.75Z" />
            <path className="cls-1" d="m178.58,393.24c10.1.01,20.19.02,30.29.03" />
            <path className="cls-1" d="m434.13,362.93c-.01,7.88-.02,15.75-.03,23.63" />
            <path className="cls-1" d="m434.12,402.43c-.01,6.72-.02,13.43-.03,20.15" />
            <path className="cls-1" d="m585.42,313.79c-.01,24.26-.02,48.51-.03,72.77" />
            <path className="cls-1" d="m583.92,314.86c12.26,0,24.52.01,36.78.02" />
            <rect className="cls-5" x="619.67" y="285.57" width="143.55" height="58.61" rx="6.96" ry="6.96" />
            <g>
              <path className="cls-8" d="m646.85,300.24v.92c0,5.13-2.32,7.24-5.84,7.24s-5.75-2.12-5.75-7.24v-1.75c0-5.13,2.23-7.24,5.75-7.24,3.08,0,5.2,1.63,5.7,5.13h-1.93c-.51-2.37-1.75-3.33-3.77-3.33-2.44,0-3.77,1.52-3.77,5.45v1.75c0,3.93,1.33,5.45,3.77,5.45,2.23,0,3.63-1.29,3.84-4.58h-3.77v-1.79h5.77Z" />
              <path className="cls-8" d="m657.86,306.47c-.8,1.4-2.02,1.93-4.02,1.93-3.08,0-4.92-2-4.92-5.2v-1.52c0-3.27,1.54-5.15,4.55-5.15s4.55,1.86,4.55,5.15v1.2h-7.27v.32c0,1.98.94,3.54,2.94,3.54,1.5,0,2.25-.44,2.74-1.29l1.43,1.01Zm-7.11-5.2h5.4c-.09-1.93-.94-3.08-2.69-3.08s-2.6,1.17-2.71,3.08Z" />
              <path className="cls-8" d="m667.87,296.72h1.84v11.27c0,3.2-1.84,4.74-4.92,4.74-2,0-3.22-.53-4.02-1.93l1.43-1.01c.48.85,1.24,1.29,2.74,1.29,2,0,2.94-1.1,2.94-3.08v-.78c-.62.8-1.61,1.2-3.13,1.2-3.13,0-4.6-2.21-4.6-5.2v-1.52c0-3.29,1.75-5.15,4.6-5.15,1.52,0,2.51.48,3.13,1.33v-1.15Zm0,6.48v-1.52c0-2.16-1.06-3.5-2.94-3.5s-2.94,1.31-2.94,3.5v1.52c0,1.96.99,3.54,2.94,3.54,2.12,0,2.94-1.56,2.94-3.54Z" />
              <path className="cls-8" d="m681.07,306.47c-.8,1.4-2.02,1.93-4.02,1.93-3.08,0-4.92-2-4.92-5.2v-1.52c0-3.27,1.54-5.15,4.55-5.15s4.55,1.86,4.55,5.15v1.2h-7.27v.32c0,1.98.94,3.54,2.94,3.54,1.5,0,2.25-.44,2.74-1.29l1.43,1.01Zm-7.11-5.2h5.4c-.09-1.93-.94-3.08-2.69-3.08s-2.6,1.17-2.71,3.08Z" />
              <path className="cls-8" d="m683.92,296.72h1.84v1.13c.58-.87,1.38-1.31,3.08-1.31,2.46,0,4.19,1.29,4.19,4.51v7.17h-1.84v-7.15c0-2.32-1.26-2.83-2.62-2.83-1.72,0-2.81,1.22-2.81,2.81v7.17h-1.84v-11.5Z" />
              <path className="cls-8" d="m703.17,292.35h1.84v15.87h-1.84v-1.01c-.62.8-1.61,1.2-3.13,1.2-3.13,0-4.6-2.21-4.6-5.2v-1.52c0-3.29,1.75-5.15,4.6-5.15,1.52,0,2.51.48,3.13,1.33v-5.52Zm0,11.01v-1.68c0-2.16-1.06-3.5-2.94-3.5s-2.94,1.31-2.94,3.5v1.52c0,1.96.99,3.54,2.94,3.54s2.94-1.47,2.94-3.38Z" />
              <path className="cls-8" d="m714.24,299.21c-.48-.67-1.08-.97-1.93-.97-1.4,0-2.46,1.06-2.46,2.78v7.2h-1.84v-11.5h1.84v1.13c.48-.81,1.26-1.31,2.67-1.31,1.56,0,2.55.53,3.29,1.56l-1.56,1.1Z" />
              <path className="cls-8" d="m726.17,308.22h-1.84v-1.13c-.58.87-1.38,1.31-3.08,1.31-2.46,0-4.19-1.29-4.19-4.51v-7.17h1.84v7.15c0,2.32,1.26,2.83,2.62,2.83,1.72,0,2.81-1.22,2.81-2.81v-7.17h1.84v11.5Z" />
              <path className="cls-8" d="m737.53,306.47c-.71,1.33-2.12,1.93-3.98,1.93-3.15,0-4.97-2.02-4.97-5.2v-1.52c0-3.27,1.86-5.15,4.97-5.15,1.72,0,3.15.62,3.98,1.79l-1.45,1.01c-.51-.74-1.47-1.15-2.53-1.15-1.98,0-3.13,1.33-3.13,3.5v1.52c0,1.98.92,3.54,3.13,3.54,1.08,0,2.07-.37,2.55-1.29l1.43,1.01Z" />
              <path className="cls-8" d="m748.25,296.72l-4.97,5.08,5.68,6.42h-2.39l-4.64-5.01-.71.76v4.25h-1.84v-15.87h1.84v9.06l4.64-4.69h2.39Z" />
            </g>
            <g>
              <path className="cls-8" d="m707.55,325.56h1.84v1.13c.51-.78,1.38-1.31,2.85-1.31s2.71.62,3.36,1.82c.78-1.22,2.05-1.82,3.45-1.82,2.46,0,3.96,1.52,3.96,4.51v7.17h-1.84v-7.15c0-2.09-1.04-2.83-2.39-2.83-1.47,0-2.58,1.17-2.58,2.81v7.17h-1.84v-7.15c0-2.09-1.03-2.83-2.39-2.83-1.49,0-2.58,1.22-2.58,2.81v7.17h-1.84v-11.5Z" />
              <path className="cls-8" d="m727.84,326.71c.62-.85,1.61-1.33,3.13-1.33,2.85,0,4.6,1.86,4.6,5.15v1.52c0,2.99-1.47,5.2-4.6,5.2-1.54,0-2.51-.39-3.13-1.2v1.01h-1.84v-15.87h1.84v5.52Zm5.89,5.33v-1.52c0-2.18-1.06-3.5-2.94-3.5s-2.94,1.26-2.94,3.33v1.68c0,1.98.83,3.54,2.94,3.54,1.95,0,2.94-1.59,2.94-3.54Z" />
              <path className="cls-8" d="m741.71,330.41h2.74v-1.33c0-1.63-.76-2-2.55-2-1.38,0-2.12.12-2.76,1.17l-1.38-.97c.8-1.33,1.98-1.91,4.16-1.91,2.92,0,4.37.94,4.37,3.7v7.98h-1.84v-1.13c-.64,1.06-1.43,1.31-2.92,1.31-2.67,0-4.12-1.31-4.12-3.54,0-2.48,1.77-3.29,4.3-3.29Zm2.74,1.56h-2.74c-1.82,0-2.46.62-2.46,1.77,0,1.29.92,1.79,2.28,1.79,1.7,0,2.92-.32,2.92-2.9v-.67Z" />
              <path className="cls-8" d="m755.51,328.04c-.48-.67-1.08-.97-1.93-.97-1.4,0-2.46,1.06-2.46,2.78v7.2h-1.84v-11.5h1.84v1.13c.48-.8,1.26-1.31,2.67-1.31,1.56,0,2.55.53,3.29,1.56l-1.56,1.1Z" />
            </g>
            <rect className="cls-5" x="832.92" y="307.93" width="143.55" height="58.61" rx="6.96" ry="6.96" />
            <g>
              <path className="cls-8" d="m861.13,315v1.79h-4.83v14.07h-1.98v-14.07h-4.83v-1.79h11.64Z" />
              <path className="cls-8" d="m869.73,329.12c-.8,1.4-2.02,1.93-4.02,1.93-3.08,0-4.92-2-4.92-5.2v-1.52c0-3.27,1.54-5.15,4.55-5.15s4.55,1.86,4.55,5.15v1.2h-7.27v.32c0,1.98.94,3.54,2.94,3.54,1.5,0,2.25-.44,2.74-1.29l1.43,1.01Zm-7.11-5.2h5.4c-.09-1.93-.94-3.08-2.69-3.08s-2.6,1.17-2.71,3.08Z" />
              <path className="cls-8" d="m872.58,319.37h1.84v1.13c.51-.78,1.38-1.31,2.85-1.31s2.71.62,3.36,1.82c.78-1.22,2.05-1.82,3.45-1.82,2.46,0,3.96,1.52,3.96,4.51v7.17h-1.84v-7.15c0-2.09-1.04-2.83-2.39-2.83-1.47,0-2.58,1.17-2.58,2.81v7.17h-1.84v-7.15c0-2.09-1.03-2.83-2.39-2.83-1.49,0-2.58,1.22-2.58,2.81v7.17h-1.84v-11.5Z" />
              <path className="cls-8" d="m895.99,319.19c2.85,0,4.6,1.86,4.6,5.15v1.52c0,2.99-1.47,5.2-4.6,5.2-1.52,0-2.51-.48-3.13-1.33v5.52h-1.84v-15.87h1.84v1.15c.62-.85,1.61-1.33,3.13-1.33Zm2.76,6.67v-1.52c0-2.18-1.06-3.5-2.94-3.5s-2.94,1.26-2.94,3.33v1.68c0,1.98.83,3.54,2.94,3.54,1.95,0,2.94-1.59,2.94-3.54Z" />
              <path className="cls-8" d="m911.38,329.12c-.8,1.4-2.02,1.93-4.02,1.93-3.08,0-4.92-2-4.92-5.2v-1.52c0-3.27,1.54-5.15,4.55-5.15s4.55,1.86,4.55,5.15v1.2h-7.27v.32c0,1.98.94,3.54,2.94,3.54,1.5,0,2.25-.44,2.74-1.29l1.43,1.01Zm-7.11-5.2h5.4c-.09-1.93-.94-3.08-2.69-3.08s-2.6,1.17-2.71,3.08Z" />
              <path className="cls-8" d="m920.47,321.86c-.48-.67-1.08-.97-1.93-.97-1.4,0-2.46,1.06-2.46,2.78v7.2h-1.84v-11.5h1.84v1.13c.48-.81,1.26-1.31,2.67-1.31,1.56,0,2.55.53,3.29,1.56l-1.56,1.1Z" />
              <path className="cls-8" d="m926.79,324.22h2.74v-1.33c0-1.63-.76-2-2.55-2-1.38,0-2.12.12-2.76,1.17l-1.38-.97c.8-1.33,1.98-1.91,4.16-1.91,2.92,0,4.37.94,4.37,3.7v7.98h-1.84v-1.13c-.64,1.06-1.42,1.31-2.92,1.31-2.67,0-4.12-1.31-4.12-3.54,0-2.48,1.77-3.29,4.3-3.29Zm2.74,1.56h-2.74c-1.82,0-2.46.62-2.46,1.77,0,1.29.92,1.79,2.28,1.79,1.7,0,2.92-.32,2.92-2.9v-.67Z" />
              <path className="cls-8" d="m933.35,319.37h1.47v-2.53h1.84v2.53h2.64v1.66h-2.64v5.84c0,1.59.18,2.25,1.72,2.25h.92v1.75h-.99c-2.71,0-3.5-1.15-3.5-3.54v-6.3h-1.47v-1.66Z" />
              <path className="cls-8" d="m950.71,330.87h-1.84v-1.13c-.58.87-1.38,1.31-3.08,1.31-2.46,0-4.19-1.29-4.19-4.51v-7.17h1.84v7.15c0,2.32,1.26,2.83,2.62,2.83,1.72,0,2.81-1.22,2.81-2.81v-7.17h1.84v11.5Z" />
              <path className="cls-8" d="m959.93,321.86c-.48-.67-1.08-.97-1.93-.97-1.4,0-2.46,1.06-2.46,2.78v7.2h-1.84v-11.5h1.84v1.13c.48-.81,1.26-1.31,2.67-1.31,1.56,0,2.55.53,3.29,1.56l-1.56,1.1Z" />
            </g>
            <g>
              <path className="cls-8" d="m929.42,341.55c1.7,0,3.08,1.33,3.08,3.08s-1.38,3.08-3.08,3.08-3.08-1.33-3.08-3.08,1.38-3.08,3.08-3.08Zm0,4.65c.85,0,1.5-.69,1.5-1.56s-.64-1.54-1.5-1.54-1.52.67-1.52,1.54.67,1.56,1.52,1.56Z" />
              <path className="cls-8" d="m943.63,346.56c-.55-2.48-1.82-3.33-3.77-3.33-2.44,0-3.77,1.52-3.77,5.45v1.75c0,3.93,1.33,5.45,3.77,5.45,2.07,0,3.24-1.01,3.77-3.33h1.91c-.51,3.56-2.67,5.13-5.68,5.13-3.52,0-5.75-2.12-5.75-7.24v-1.75c0-5.13,2.23-7.24,5.75-7.24,3.13,0,5.2,1.66,5.7,5.13h-1.93Z" />
            </g>
            <path className="cls-1" d="m904.72,366.46c-.01,6.7-.02,13.4-.03,20.1" />
            <path className="cls-1" d="m1061.89,365.86c-.01,6.9-.02,13.8-.03,20.7" />
            <path className="cls-1" d="m1061.88,402.2c-.01,6.55-.02,13.1-.03,19.65" />
            <rect className="cls-5" x="991.98" y="307.93" width="143.55" height="58.61" rx="6.96" ry="6.96" />
            <path className="cls-8" d="m1056.3,330.87c-3.52,0-5.75-2.12-5.75-7.24v-1.75c0-5.13,2.23-7.24,5.75-7.24s5.75,2.12,5.75,7.24v1.75c0,5.13-2.23,7.24-5.75,7.24Zm-3.77-7.24c0,3.93,1.33,5.45,3.77,5.45s3.77-1.52,3.77-5.45v-1.75c0-3.93-1.33-5.45-3.77-5.45s-3.77,1.52-3.77,5.45v1.75Z" />
            <g>
              <path className="cls-8" d="m1080.64,345.98c0,2.07-.99,3.61-3.33,3.61-2.09,0-3.29-1.38-3.29-3.61v-.99c0-2.23,1.24-3.56,3.29-3.56s3.33,1.31,3.33,3.56v.99Zm-1.45-.02v-.92c0-1.43-.64-2.21-1.88-2.21-1.17,0-1.82.8-1.82,2.21v.92c0,1.29.46,2.25,1.82,2.25s1.88-.99,1.88-2.25Zm7.91-4.35l-8.85,15.87h-1.93l8.88-15.87h1.91Zm2.55,12.44c0,2.07-.99,3.61-3.33,3.61-2.09,0-3.29-1.38-3.29-3.61v-.99c0-2.23,1.24-3.56,3.29-3.56s3.33,1.31,3.33,3.56v.99Zm-1.45-.02v-.92c0-1.43-.64-2.21-1.89-2.21-1.17,0-1.82.8-1.82,2.21v.92c0,1.29.46,2.25,1.82,2.25s1.89-.99,1.89-2.25Z" />
              <path className="cls-8" d="m1104.51,341.61h1.84v15.87h-1.84v-1.01c-.62.8-1.61,1.2-3.13,1.2-3.13,0-4.6-2.21-4.6-5.2v-1.52c0-3.29,1.75-5.15,4.6-5.15,1.52,0,2.51.48,3.13,1.33v-5.52Zm0,11.01v-1.68c0-2.16-1.06-3.5-2.94-3.5s-2.94,1.31-2.94,3.5v1.52c0,1.96.99,3.54,2.94,3.54s2.94-1.47,2.94-3.38Z" />
              <path className="cls-8" d="m1115.57,348.47c-.48-.67-1.08-.97-1.93-.97-1.4,0-2.46,1.06-2.46,2.78v7.2h-1.84v-11.5h1.84v1.13c.48-.81,1.26-1.31,2.67-1.31,1.56,0,2.55.53,3.29,1.56l-1.56,1.1Z" />
              <path className="cls-8" d="m1121.53,357.6l-4.51-11.61h1.98l3.43,8.92,3.01-8.92h1.95l-4.05,11.87c-.94,2.76-1.56,4-4.78,4v-1.79c1.52,0,2.23-.25,2.92-2.3l.05-.16Z" />
            </g>
            <rect className="cls-5" x="983.25" y="420.49" width="157.2" height="86.88" rx="6.96" ry="6.96" />
            <g>
              <path className="cls-8" d="m1046.81,426.59v15.87h-1.93l-6.85-12v12h-1.98v-15.87h1.89l6.9,12.12v-12.12h1.98Z" />
              <path className="cls-8" d="m1055.67,442.64c-3.52,0-5.75-2.12-5.75-7.24v-1.75c0-5.13,2.23-7.24,5.75-7.24s5.75,2.12,5.75,7.24v1.75c0,5.13-2.23,7.24-5.75,7.24Zm-3.77-7.24c0,3.93,1.33,5.45,3.77,5.45s3.77-1.52,3.77-5.45v-1.75c0-3.93-1.33-5.45-3.77-5.45s-3.77,1.52-3.77,5.45v1.75Z" />
            </g>
            <g>
              <path className="cls-8" d="m1051.54,458.37h1.84v1.13c.51-.78,1.38-1.31,2.85-1.31s2.71.62,3.36,1.82c.78-1.22,2.05-1.82,3.45-1.82,2.46,0,3.96,1.52,3.96,4.51v7.17h-1.84v-7.15c0-2.09-1.04-2.83-2.39-2.83-1.47,0-2.58,1.17-2.58,2.81v7.17h-1.84v-7.15c0-2.09-1.03-2.83-2.39-2.83-1.49,0-2.58,1.22-2.58,2.81v7.17h-1.84v-11.5Z" />
              <path className="cls-8" d="m1077.14,458.37h1.84v11.27c0,3.2-1.84,4.74-4.92,4.74-2,0-3.22-.53-4.02-1.93l1.43-1.01c.48.85,1.24,1.29,2.74,1.29,2,0,2.94-1.1,2.94-3.08v-.78c-.62.8-1.61,1.2-3.13,1.2-3.13,0-4.6-2.21-4.6-5.2v-1.52c0-3.29,1.75-5.15,4.6-5.15,1.52,0,2.51.48,3.13,1.33v-1.15Zm0,6.49v-1.52c0-2.16-1.06-3.5-2.94-3.5s-2.94,1.31-2.94,3.5v1.52c0,1.95.99,3.54,2.94,3.54,2.12,0,2.94-1.56,2.94-3.54Z" />
              <path className="cls-8" d="m1081.56,472.53l7.73-21.34h1.77l-7.73,21.34h-1.77Z" />
              <path className="cls-8" d="m1104.35,454v15.87h-1.93l-6.85-12v12h-1.98v-15.87h1.89l6.9,12.12v-12.12h1.98Z" />
              <path className="cls-8" d="m1107.8,458.37h1.84v1.13c.51-.78,1.38-1.31,2.85-1.31s2.71.62,3.36,1.82c.78-1.22,2.05-1.82,3.45-1.82,2.46,0,3.96,1.52,3.96,4.51v7.17h-1.84v-7.15c0-2.09-1.04-2.83-2.39-2.83-1.47,0-2.58,1.17-2.58,2.81v7.17h-1.84v-7.15c0-2.09-1.03-2.83-2.39-2.83-1.49,0-2.58,1.22-2.58,2.81v7.17h-1.84v-11.5Z" />
              <path className="cls-8" d="m1127.23,456.94h.44c.87,0,1.36-.32,1.36-.99,0-.58-.41-.87-.99-.87-.67,0-1.03.34-1.13.87h-1.24c0-1.15,1.04-2.02,2.37-2.02s2.21.71,2.21,1.93c0,.85-.53,1.33-1.2,1.63.78.21,1.33.85,1.33,1.72,0,1.36-.99,2.12-2.35,2.12-1.49,0-2.39-.97-2.46-2.07h1.26c.09.53.46.92,1.2.92.6,0,1.1-.3,1.1-1.01,0-.62-.39-1.08-1.47-1.08h-.44v-1.15Z" />
            </g>
            <g>
              <path className="cls-8" transform="translate(-15, 0)" d="m1021.68,492.32c0,1.84.8,2.18,1.63,2.18,1.52,0,2-1.43,2-3.36,0-5.59-3.98-7.75-7.57-7.75-5.27,0-7.57,4.14-7.57,7.75,0,5.08,3.66,7.73,7.68,7.73,1.88,0,3.4-.32,5.06-1.15l.71,1.31c-.64.55-2.92,1.49-5.89,1.49-4.67,0-9.41-3.29-9.41-9.38s4.53-9.4,9.41-9.4,9.4,3.43,9.4,9.4c0,2.83-1.17,5.01-3.89,5.01-1.17,0-2.28-.51-2.78-1.59-.46,1.06-1.36,1.59-2.97,1.59-2.62,0-3.86-1.86-3.86-4.37v-1.2c0-2.78,1.45-4.35,3.86-4.35,1.06,0,1.79.28,2.32.76v-.64h1.84v5.96Zm-1.84-.62v-1.26c0-1.68-.74-2.53-2.16-2.53-1.52,0-2.18.92-2.18,2.78v1.01c0,1.7.62,2.81,2.18,2.81,1.7,0,2.16-1.1,2.16-2.81Z" />
              <path className="cls-8" d="m1058.28,486c0,2.07-.99,3.61-3.33,3.61-2.09,0-3.29-1.38-3.29-3.61v-.99c0-2.23,1.24-3.56,3.29-3.56s3.33,1.31,3.33,3.56v.99Zm-1.45-.02v-.92c0-1.43-.64-2.21-1.88-2.21-1.17,0-1.82.8-1.82,2.21v.92c0,1.29.46,2.25,1.82,2.25s1.88-.99,1.88-2.25Zm7.91-4.35l-8.85,15.87h-1.93l8.88-15.87h1.91Zm2.55,12.44c0,2.07-.99,3.61-3.33,3.61-2.09,0-3.29-1.38-3.29-3.61v-.99c0-2.23,1.24-3.56,3.29-3.56s3.33,1.31,3.33,3.56v.99Zm-1.45-.02v-.92c0-1.43-.64-2.21-1.89-2.21-1.17,0-1.82.8-1.82,2.21v.92c0,1.29.46,2.25,1.82,2.25s1.89-.99,1.89-2.25Z" />
              <path className="cls-8" d="m1080.4,497.68c-3.52,0-5.75-2.12-5.75-7.24v-1.75c0-5.13,2.23-7.24,5.75-7.24s5.75,2.12,5.75,7.24v1.75c0,5.13-2.23,7.24-5.75,7.24Zm-3.77-7.24c0,3.93,1.33,5.45,3.77,5.45s3.77-1.52,3.77-5.45v-1.75c0-3.93-1.33-5.45-3.77-5.45s-3.77,1.52-3.77,5.45v1.75Z" />
            </g>
            <rect className="cls-5" x="554.7" y="122.74" width="145.88" height="58.59" rx="6.96" ry="6.96" />
            <g>
              <path className="cls-8" d="m571.41,147.2v-15.87h5.06c3.52,0,5.75,1.98,5.75,7.11v1.66c0,5.13-2.23,7.11-5.75,7.11h-5.06Zm8.83-7.11v-1.66c0-3.93-1.33-5.31-3.77-5.31h-3.08v12.28h3.08c2.44,0,3.77-1.38,3.77-5.31Z" />
              <path className="cls-8" d="m591.1,138.19c-.48-.67-1.08-.97-1.93-.97-1.4,0-2.46,1.06-2.46,2.78v7.2h-1.84v-11.5h1.84v1.13c.48-.81,1.26-1.31,2.67-1.31,1.56,0,2.55.53,3.29,1.56l-1.56,1.1Z" />
              <path className="cls-8" d="m603.03,147.2h-1.84v-1.13c-.58.87-1.38,1.31-3.08,1.31-2.46,0-4.18-1.29-4.18-4.51v-7.17h1.84v7.15c0,2.32,1.26,2.83,2.62,2.83,1.72,0,2.81-1.22,2.81-2.81v-7.17h1.84v11.5Z" />
              <path className="cls-8" d="m614.39,145.45c-.71,1.33-2.12,1.93-3.98,1.93-3.15,0-4.97-2.02-4.97-5.2v-1.52c0-3.27,1.86-5.15,4.97-5.15,1.72,0,3.15.62,3.98,1.79l-1.45,1.01c-.51-.74-1.47-1.15-2.53-1.15-1.98,0-3.13,1.33-3.13,3.5v1.52c0,1.98.92,3.54,3.13,3.54,1.08,0,2.07-.37,2.55-1.29l1.43,1.01Z" />
              <path className="cls-8" d="m625.11,135.7l-4.97,5.08,5.68,6.42h-2.39l-4.64-5.01-.71.76v4.25h-1.84v-15.87h1.84v9.06l4.65-4.69h2.39Z" />
              <path className="cls-8" d="m632.38,139.73h5.96v1.66h-5.96v-1.66Z" />
              <path className="cls-8" d="m648.71,138.09h6.81v-6.76h1.98v15.87h-1.98v-7.31h-6.81v7.31h-1.98v-15.87h1.98v6.76Z" />
              <path className="cls-8" d="m665.52,147.2h-1.98l-3.86-15.87h2.07l2.87,12.46,3.5-12.46h1.96l3.56,12.53,2.87-12.53h2.07l-3.86,15.87h-2l-3.61-12.33-3.59,12.33Z" />
              <path className="cls-8" d="m682.75,145.41h7.59v1.79h-9.57v-15.87h1.98v14.07Z" />
            </g>
            <g>
              <path className="cls-8" d="m632.92,161.24h1.84v1.13c.51-.78,1.38-1.31,2.85-1.31s2.71.62,3.36,1.82c.78-1.22,2.05-1.82,3.45-1.82,2.46,0,3.96,1.52,3.96,4.51v7.17h-1.84v-7.15c0-2.09-1.04-2.83-2.39-2.83-1.47,0-2.58,1.17-2.58,2.81v7.17h-1.84v-7.15c0-2.09-1.04-2.83-2.39-2.83-1.49,0-2.57,1.22-2.57,2.81v7.17h-1.84v-11.5Z" />
              <path className="cls-8" d="m653.21,162.39c.62-.85,1.61-1.33,3.13-1.33,2.85,0,4.6,1.86,4.6,5.15v1.52c0,2.99-1.47,5.2-4.6,5.2-1.54,0-2.51-.39-3.13-1.2v1.01h-1.84v-15.87h1.84v5.52Zm5.89,5.33v-1.52c0-2.18-1.06-3.5-2.94-3.5s-2.94,1.26-2.94,3.33v1.68c0,1.98.83,3.54,2.94,3.54,1.96,0,2.94-1.59,2.94-3.54Z" />
              <path className="cls-8" d="m667.08,166.09h2.74v-1.33c0-1.63-.76-2-2.55-2-1.38,0-2.12.12-2.76,1.17l-1.38-.97c.8-1.33,1.98-1.91,4.16-1.91,2.92,0,4.37.94,4.37,3.7v7.98h-1.84v-1.13c-.64,1.06-1.42,1.31-2.92,1.31-2.67,0-4.12-1.31-4.12-3.54,0-2.48,1.77-3.29,4.3-3.29Zm2.74,1.56h-2.74c-1.82,0-2.46.62-2.46,1.77,0,1.29.92,1.79,2.28,1.79,1.7,0,2.92-.32,2.92-2.9v-.67Z" />
              <path className="cls-8" d="m680.88,163.72c-.48-.67-1.08-.97-1.93-.97-1.4,0-2.46,1.06-2.46,2.78v7.2h-1.84v-11.5h1.84v1.13c.48-.81,1.27-1.31,2.67-1.31,1.56,0,2.55.53,3.29,1.56l-1.56,1.1Z" />
            </g>
            <path className="cls-1" d="m535.19,97.75c.01,87.36.03,174.73.04,262.09" />
            <path className="cls-1" d="m486.9,97.55c.02,54.49.03,108.98.05,163.48" />
            <path className="cls-1" d="m485.45,260.06c15.44.01,30.87.02,46.31.03" />
            <path className="cls-1" d="m521.14,278.23c-.01,28.87-.02,57.73-.03,86.6" />
            <circle className="cls-4" cx="535.23" cy="258.55" r="7.88" />
            <polygon className="cls-4" points="529.45 385.22 516.09 358.82 542.82 358.82 529.45 385.22" />
            <circle className="cls-4" cx="355.45" cy="99.02" r="7.88" />
            <path className="cls-1" d="m354.19,279.71c55.65.01,111.3.02,166.95.03" />
            <path className="cls-1" d="m253.17,99.02c78.09,0,156.18.01,234.27.02" />
            <path className="cls-1" d="m355.72,74.67c0,68.36-.01,136.71-.02,205.07" />
            <polygon className="cls-4" points="444.89 99.02 423.55 116.85 423.55 81.19 444.89 99.02" />
            <polygon className="cls-4" points="443.4 99.02 464.73 116.85 464.73 81.19 443.4 99.02" />
            <path className="cls-1" d="m832.92,99.02c-99.74.01-199.48.03-299.23.04" />
            <path className="cls-1" d="m626.59,97.75c0,8.33,0,16.66,0,24.99" />
            <path className="cls-1" d="m832.93,97.52c0,7.24,0,14.48,0,21.72" />
            <path className="cls-1" d="m919.29,94.91c0,8.11,0,16.23,0,24.34" />
            <path className="cls-1" d="m733.64,59.09c0,3.74,0,7.49,0,11.23" />
            <rect className="cls-5" x="660.7" y=".5" width="145.88" height="58.59" rx="6.96" ry="6.96" />
            <g>
              <path className="cls-8" d="m688.29,25.65v-15.87h5.06c3.52,0,5.75,1.98,5.75,7.11v1.66c0,5.13-2.23,7.11-5.75,7.11h-5.06Zm8.83-7.11v-1.66c0-3.93-1.33-5.31-3.77-5.31h-3.08v12.28h3.08c2.44,0,3.77-1.38,3.77-5.31Z" />
              <path className="cls-8" d="m710.74,20.64c0,2.99-1.47,5.2-4.78,5.2-3.06,0-4.78-2.02-4.78-5.2v-1.52c0-3.27,1.77-5.15,4.78-5.15s4.78,1.86,4.78,5.15v1.52Zm-1.84,0v-1.52c0-2.18-1.06-3.5-2.94-3.5s-2.94,1.33-2.94,3.5v1.52c0,1.98.83,3.54,2.94,3.54,1.95,0,2.94-1.59,2.94-3.54Z" />
              <path className="cls-8" d="m719.61,16.85c-.62-.8-1.26-1.17-2.85-1.17-1.36,0-2.23.53-2.23,1.54,0,1.31,1.15,1.7,2.67,1.82,2.16.16,4.14.78,4.14,3.43,0,2-1.36,3.38-4.32,3.38-2.35,0-3.56-.71-4.44-1.91l1.36-.97c.62.83,1.45,1.17,3.1,1.17,1.54,0,2.46-.6,2.46-1.63,0-1.24-.74-1.68-2.97-1.84-1.93-.14-3.84-.92-3.84-3.43,0-1.91,1.38-3.27,4.12-3.27,1.86,0,3.29.48,4.19,1.91l-1.38.97Z" />
              <path className="cls-8" d="m724.1,9.79h2.07v2.07h-2.07v-2.07Zm.11,4.37h1.84v11.5h-1.84v-11.5Z" />
              <path className="cls-8" d="m737.76,23.9c-.8,1.4-2.02,1.93-4.02,1.93-3.08,0-4.92-2-4.92-5.2v-1.52c0-3.27,1.54-5.15,4.55-5.15s4.55,1.86,4.55,5.15v1.2h-7.27v.32c0,1.98.94,3.54,2.94,3.54,1.5,0,2.25-.44,2.74-1.29l1.43,1.01Zm-7.11-5.2h5.4c-.09-1.93-.94-3.08-2.69-3.08s-2.6,1.17-2.71,3.08Z" />
              <path className="cls-8" d="m746.85,16.64c-.48-.67-1.08-.97-1.93-.97-1.4,0-2.46,1.06-2.46,2.78v7.2h-1.84v-11.5h1.84v1.13c.48-.81,1.26-1.31,2.67-1.31,1.56,0,2.55.53,3.29,1.56l-1.56,1.1Z" />
              <path className="cls-8" d="m758.78,25.65h-1.84v-1.13c-.58.87-1.38,1.31-3.08,1.31-2.46,0-4.18-1.29-4.18-4.51v-7.17h1.84v7.15c0,2.32,1.26,2.83,2.62,2.83,1.72,0,2.81-1.22,2.81-2.81v-7.17h1.84v11.5Z" />
              <path className="cls-8" d="m761.77,14.15h1.84v1.13c.57-.87,1.38-1.31,3.08-1.31,2.46,0,4.18,1.29,4.18,4.51v7.17h-1.84v-7.15c0-2.32-1.26-2.83-2.62-2.83-1.72,0-2.8,1.22-2.8,2.81v7.17h-1.84v-11.5Z" />
              <path className="cls-8" d="m781.02,14.15h1.84v11.27c0,3.2-1.84,4.74-4.92,4.74-2,0-3.22-.53-4.02-1.93l1.43-1.01c.48.85,1.24,1.29,2.74,1.29,2,0,2.94-1.1,2.94-3.08v-.78c-.62.8-1.61,1.2-3.13,1.2-3.13,0-4.6-2.21-4.6-5.2v-1.52c0-3.29,1.75-5.15,4.6-5.15,1.52,0,2.51.48,3.13,1.33v-1.15Zm0,6.48v-1.52c0-2.16-1.06-3.5-2.94-3.5s-2.94,1.31-2.94,3.5v1.52c0,1.95.99,3.54,2.94,3.54,2.12,0,2.94-1.56,2.94-3.54Z" />
            </g>
            <g>
              <path className="cls-8" d="m763.14,35.2h1.84v11.87c0,1.24.07,2.3,1.95,2.3v1.7c-2.9,0-3.79-1.15-3.79-3.54v-12.33Z" />
              <path className="cls-8" d="m769.03,53.73l7.73-21.34h1.77l-7.73,21.34h-1.77Z" />
              <path className="cls-8" d="m780.6,35.2h1.84v5.5c.58-.87,1.38-1.31,3.08-1.31,2.46,0,4.19,1.29,4.19,4.51v7.17h-1.84v-7.15c0-2.32-1.26-2.83-2.62-2.83-1.72,0-2.81,1.22-2.81,2.81v7.17h-1.84v-15.87Z" />
            </g>
            <rect className="cls-5" x="847.73" y="36.32" width="145.88" height="58.59" rx="6.96" ry="6.96" />
            <g>
              <path className="cls-8" d="m886.48,44.51v1.79h-7.59v5.33h6.9v1.79h-6.9v6.94h-1.98v-15.87h9.57Z" />
              <path className="cls-8" d="m897.77,60.38h-1.84v-1.13c-.58.87-1.38,1.31-3.08,1.31-2.46,0-4.19-1.29-4.19-4.51v-7.17h1.84v7.15c0,2.32,1.26,2.83,2.62,2.83,1.72,0,2.81-1.22,2.81-2.81v-7.17h1.84v11.5Zm-8-15.87h2.07v2.07h-2.07v-2.07Zm4.83,0h2.07v2.07h-2.07v-2.07Z" />
              <path className="cls-8" d="m900.76,44.51h1.84v11.87c0,1.24.07,2.3,1.96,2.3v1.7c-2.9,0-3.79-1.15-3.79-3.54v-12.33Z" />
              <path className="cls-8" d="m907.06,44.51h1.84v11.87c0,1.24.07,2.3,1.96,2.3v1.7c-2.9,0-3.79-1.15-3.79-3.54v-12.33Z" />
              <path className="cls-8" d="m919.83,51.57c-.62-.8-1.26-1.17-2.85-1.17-1.36,0-2.23.53-2.23,1.54,0,1.31,1.15,1.7,2.67,1.82,2.16.16,4.14.78,4.14,3.43,0,2-1.36,3.38-4.32,3.38-2.35,0-3.56-.71-4.44-1.91l1.36-.97c.62.83,1.45,1.17,3.1,1.17,1.54,0,2.46-.6,2.46-1.63,0-1.24-.74-1.68-2.97-1.84-1.93-.14-3.84-.92-3.84-3.43,0-1.91,1.38-3.27,4.12-3.27,1.86,0,3.29.48,4.19,1.91l-1.38.97Z" />
              <path className="cls-8" d="m923.07,48.88h1.47v-2.53h1.84v2.53h2.64v1.66h-2.64v5.84c0,1.59.18,2.25,1.73,2.25h.92v1.75h-.99c-2.71,0-3.5-1.15-3.5-3.54v-6.3h-1.47v-1.66Z" />
              <path className="cls-8" d="m935.05,53.73h2.74v-1.33c0-1.63-.76-2-2.55-2-1.38,0-2.12.12-2.76,1.17l-1.38-.97c.8-1.33,1.98-1.91,4.16-1.91,2.92,0,4.37.94,4.37,3.7v7.98h-1.84v-1.13c-.64,1.06-1.42,1.31-2.92,1.31-2.67,0-4.12-1.31-4.12-3.54,0-2.48,1.77-3.29,4.3-3.29Zm2.74,1.56h-2.74c-1.82,0-2.46.62-2.46,1.77,0,1.29.92,1.79,2.28,1.79,1.7,0,2.92-.32,2.92-2.9v-.67Z" />
              <path className="cls-8" d="m942.62,48.88h1.84v1.13c.57-.87,1.38-1.31,3.08-1.31,2.46,0,4.18,1.29,4.18,4.51v7.17h-1.84v-7.15c0-2.32-1.26-2.83-2.62-2.83-1.72,0-2.8,1.22-2.8,2.81v7.17h-1.84v-11.5Z" />
              <path className="cls-8" d="m961.87,44.51h1.84v15.87h-1.84v-1.01c-.62.8-1.61,1.2-3.13,1.2-3.13,0-4.6-2.21-4.6-5.2v-1.52c0-3.29,1.75-5.15,4.6-5.15,1.52,0,2.51.48,3.13,1.33v-5.52Zm0,11.01v-1.68c0-2.16-1.06-3.5-2.94-3.5s-2.94,1.31-2.94,3.5v1.52c0,1.95.99,3.54,2.94,3.54s2.94-1.47,2.94-3.38Z" />
            </g>
            <path className="cls-8" d="m946.73,75.57c0,2.07-.99,3.61-3.33,3.61-2.09,0-3.29-1.38-3.29-3.61v-.99c0-2.23,1.24-3.56,3.29-3.56s3.33,1.31,3.33,3.56v.99Zm-1.45-.02v-.92c0-1.43-.64-2.21-1.88-2.21-1.17,0-1.82.81-1.82,2.21v.92c0,1.29.46,2.25,1.82,2.25s1.88-.99,1.88-2.25Zm7.91-4.35l-8.85,15.87h-1.93l8.88-15.87h1.91Zm2.55,12.44c0,2.07-.99,3.61-3.33,3.61-2.09,0-3.29-1.38-3.29-3.61v-.99c0-2.23,1.24-3.56,3.29-3.56s3.33,1.31,3.33,3.56v.99Zm-1.45-.02v-.92c0-1.43-.64-2.21-1.89-2.21-1.17,0-1.82.8-1.82,2.21v.92c0,1.29.46,2.25,1.82,2.25s1.89-.99,1.89-2.25Z" />
            <circle className="cls-5" cx="733.64" cy="99.65" r="30.71" />
            <path className="cls-4" d="m734.78,68.19c-10.6,10.23-21.2,20.46-31.8,30.68" />
            <path className="cls-4" d="m702.98,98.2c10.93,10.54,21.85,21.08,32.78,31.63" />
            <polygon className="cls-3" points="736.99 129.7 735.11 130.26 735.56 128.35 736.99 129.7" />
            <rect className="cls-5" x="805.74" y="119.25" width="144.71" height="114.95" />
            <g>
              <path className="cls-8" d="m834.82,155.73v1.79h-4.83v14.07h-1.98v-14.07h-4.83v-1.79h11.64Z" />
              <path className="cls-8" d="m839,164.95h2.74v-1.33c0-1.63-.76-2-2.55-2-1.38,0-2.12.12-2.76,1.17l-1.38-.97c.8-1.33,1.98-1.91,4.16-1.91,2.92,0,4.37.94,4.37,3.7v7.98h-1.84v-1.13c-.64,1.06-1.42,1.31-2.92,1.31-2.67,0-4.12-1.31-4.12-3.54,0-2.48,1.77-3.29,4.3-3.29Zm2.74,1.56h-2.74c-1.82,0-2.46.62-2.46,1.77,0,1.29.92,1.79,2.28,1.79,1.7,0,2.92-.32,2.92-2.9v-.67Z" />
              <path className="cls-8" d="m846.57,160.1h1.84v1.13c.57-.87,1.38-1.31,3.08-1.31,2.46,0,4.18,1.29,4.18,4.51v7.17h-1.84v-7.15c0-2.32-1.26-2.83-2.62-2.83-1.72,0-2.8,1.22-2.8,2.81v7.17h-1.84v-11.5Z" />
              <path className="cls-8" d="m867.55,160.1l-4.97,5.08,5.68,6.42h-2.39l-4.64-5.01-.71.76v4.25h-1.84v-15.87h1.84v9.06l4.64-4.69h2.39Z" />
              <path className="cls-8" d="m827,190.09h6.81v-6.76h1.98v15.87h-1.98v-7.31h-6.81v7.31h-1.98v-15.87h1.98v6.76Z" />
              <path className="cls-8" d="m843.81,199.19h-1.98l-3.86-15.87h2.07l2.88,12.46,3.5-12.46h1.95l3.56,12.53,2.88-12.53h2.07l-3.86,15.87h-2l-3.61-12.33-3.59,12.33Z" />
              <path className="cls-8" d="m861.04,197.4h7.59v1.79h-9.57v-15.87h1.98v14.07Z" />
            </g>
            <rect className="cls-5" x="889.05" y="133.78" width="61.71" height="100.42" />
            <rect className="cls-7" x="889.05" y="119.25" width="61.71" height="14.53" />
            <path className="cls-8" d="m115.53,186.57l-2.43-3.35-2.43,3.35h-1.69l3.28-4.61-2.92-4.39h1.69l2.07,3.19,2.07-3.19h1.69l-2.92,4.39,3.28,4.61h-1.69Z" />
            <path className="cls-8" d="m433.43,331.36h-5.67v-1.01l3.4-4.02c.66-.77,1.09-1.43,1.09-2.16,0-1.01-.77-1.51-1.57-1.51-.87,0-1.56.52-1.58,1.51h-1.19c0-1.6,1.26-2.59,2.77-2.59s2.77.99,2.77,2.65c0,1.15-.84,2.07-1.46,2.82l-2.66,3.22h4.09v1.09Z" />
            <path className="cls-8" d="m1069.09,334.05h-5.67v-1.01l3.4-4.02c.66-.77,1.09-1.43,1.09-2.16,0-1.01-.77-1.51-1.57-1.51-.87,0-1.56.52-1.58,1.51h-1.19c0-1.6,1.26-2.59,2.77-2.59s2.77.99,2.77,2.65c0,1.15-.84,2.07-1.46,2.82l-2.66,3.22h4.09v1.09Z" />
            <path className="cls-8" d="m1070.49,446.34l-2.43-3.35-2.43,3.35h-1.69l3.28-4.61-2.92-4.39h1.69l2.07,3.19,2.07-3.19h1.69l-2.92,4.39,3.28,4.61h-1.69Z" />
            <path className="cls-8" d="m449.58,448.25l-2.43-3.35-2.43,3.35h-1.69l3.28-4.61-2.92-4.39h1.69l2.07,3.19,2.07-3.19h1.69l-2.92,4.39,3.28,4.61h-1.69Z" />
            <path className="cls-8" d="m178.02,236.92h-5.67v-1.01l3.4-4.02c.66-.77,1.09-1.43,1.09-2.16,0-1.01-.77-1.51-1.57-1.51-.87,0-1.56.52-1.58,1.51h-1.19c0-1.6,1.26-2.59,2.77-2.59s2.77.99,2.77,2.65c0,1.15-.84,2.07-1.46,2.82l-2.66,3.22h4.09v1.09Z" />
            <path className="cls-8" d="m473.14,502.61h-5.67v-1.01l3.4-4.02c.66-.77,1.09-1.43,1.09-2.16,0-1.01-.77-1.51-1.57-1.51-.87,0-1.56.52-1.58,1.51h-1.19c0-1.6,1.26-2.59,2.77-2.59s2.77,1,2.77,2.65c0,1.15-.84,2.07-1.46,2.82l-2.66,3.22h4.09v1.09Z" />
            <path className="cls-8" d="m1092.89,500.53h-5.67v-1.01l3.4-4.02c.66-.77,1.09-1.43,1.09-2.16,0-1.01-.77-1.51-1.57-1.51-.87,0-1.55.52-1.58,1.51h-1.19c0-1.6,1.26-2.59,2.77-2.59s2.77.99,2.77,2.65c0,1.15-.84,2.07-1.46,2.82l-2.66,3.22h4.09v1.09Z" />
            {/* <!-- Hier die Labels --> */}
            {/* <!-- Status Label (Passiv) --> */}
            {reported?.sStatus && reported.sStatus.includes('Warnung') ? (
              <text className="cls-label-warn" textAnchor="middle" x="105" y="33">{`⚠ ${reported.sStatus ?? ''}`}</text>
            ) : reported?.sStatus && (reported?.sStatus.includes('Alarm') || reported?.sStatus.includes('Störung') || reported?.sStatus.includes('Defekt')) ? (
              <text className="cls-label-alarm" textAnchor="middle" x="105" y="33">{`⚠ ${reported?.sStatus ?? ''}`}</text>
            ) : (
              <text className="cls-label" textAnchor="middle" x="105" y="33">{reported?.sStatus ?? ''}</text>
            )}
            {/* <!-- Druck Luft --> */}
            <text className="cls-label" textAnchor="end" x="357" y="66">{roundShadowValue(reported?.Var_p_Air, 1)}</text>
            {/* <!-- Dosierung (l/h)  --> */}
            <text className="cls-label" textAnchor="end" x="747" y="51">{roundShadowValue(reported?.Var_V_UWS, 1)}</text>
            {/* <!-- Füllstand (%)  --> */}
            <text className="cls-label" textAnchor="end" x="935" y="86">{roundShadowValue(reported?.Var_Level, 1)}</text>
            {/* <!-- Füllstand Grafik --> */}
            <rect className="cls-water-level" x="889.5" y={(100 - (reported?.Var_Level ?? 0)) + 134} width="61" height={reported?.Var_Level ?? 0} />
            {/* <!-- Timestamp --> */}
            <rect
              x="940"
              y="2"
              width="200"
              height="30"
              rx="10"
              ry="10"
              fill="white" // Fill color of the rectangle
              stroke="black" // Border color of the rectangle
              strokeWidth="1" // Border width of the rectangle
            />
            <text className="cls-label" textAnchor="end" x="1130" y="26">{reportedTimestamp ? convertTimestamp(reportedTimestamp, true) : ''}</text>
            {/* <!-- Nox Zielwert 1 --> */}
            <text className="cls-label" textAnchor="end" x="121" y="209">{roundShadowValue(reported?.Var_NOx_Target_mgO2, 1)}</text>
            {/* o2_bezug ist device.O_B in EmiconInfos */}
            <text className="cls-label" textAnchor="end" x="134" y="234">{roundShadowValue(reported?.Var_RelO2, 1)}</text>
            {/* <!-- Druck HWL --> */}
            <text className="cls-label" textAnchor="end" x="628" y="173">{roundShadowValue(reported?.Var_p_UWS, 1)}</text>
            {/* <!-- Motorlast --> */}
            <text className="cls-label" textAnchor="end" x="130" y="418">{roundShadowValue(reported?.Var_EnLoad, 1)}</text>
            {/* <!-- O2 Dry links --> */}
            <text className="cls-label" textAnchor="end" x="435" y="352">{roundShadowValue(reported?.Var_O2_In_c, 1)}</text>
            {/* <!-- Gegendruck --> */}
            <text className="cls-label" textAnchor="end" x="703" y="337">{roundShadowValue(reported?.Var_p_DiffCat, 1)}</text>
            {/* <!-- Temperatur --> */}
            <text className="cls-label" textAnchor="end" x="922" y="356">{roundShadowValue(reported?.Var_T_Exhaust, 1)}</text>
            {/* <!-- O2 Dry rechts --> */}
            <text className="cls-label" textAnchor="end" x="1070" y="356">{roundShadowValue(reported?.Var_O2_Out_c, 1)}</text>
            {/* <!-- NOx mg/Nm links --> */}
            <text className="cls-label" textAnchor="end" x="428" y="472">{roundShadowValue(reported?.Var_NOx_In_mgO2, 1)}</text>
            <text className="cls-label" textAnchor="end" x="430" y="499">{roundShadowValue(reported?.Var_RelO2, 1)}</text>
            {/* <!-- NOx mg/Nm rechts --> */}
            <text className="cls-label" textAnchor="end" x="1048" y="470">{roundShadowValue(reported?.Var_NOx_Out_mgO2, 1)}</text>
            <text className="cls-label" textAnchor="end" x="1050" y="497">{roundShadowValue(reported?.Var_RelO2, 1)}</text>
            {/* <!-- Anlagennummer --> */}
            <text className="cls-label" textAnchor="middle" x="108" y="521">{reported?.sSeriennummer}</text>
            {/* <!-- Version --> */}
            <text className="cls-label" textAnchor="middle" x="287" y="521">{reported?.sVersion}</text>
          </svg>
          {/* <img src={ReactLogo} alt="React Logo" /> */}
          {/* <label style={{ left: '2%', top: '10px', position: 'absolute' }}>asd</label> */}
        </CardContent>
      </Card>
    ) : (
      null
    )
  );
}
