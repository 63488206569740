import React from 'react';
import { Scatter } from 'react-chartjs-2';

const blue = 'rgba(0, 127, 172, 1)';
const green = 'rgba(82, 174, 50, 1)';
const red = 'rgba(150,0,0, 1)';

interface ChartProps {
  chartData: Object[]
  type: string
  limits: Object[]
  fullSize?: boolean
  showHmw?: boolean
  days: number
}

function Chart({
  chartData, type, limits, fullSize, days, showHmw,
}: ChartProps) {
  let color;
  let label;
  let limitLabel;
  let yAxisLabel;

  let pointSize;
  let pointHitSize;
  let unitStepSize;

  if (showHmw && fullSize) {
    if (days <= 60) {
      pointSize = 1;
      pointHitSize = 4;
      unitStepSize = 3;
    } else if (days <= 120) {
      pointSize = 1;
      pointHitSize = 4;
      unitStepSize = 5;
    } else if (days <= 270) {
      pointSize = 1;
      pointHitSize = 4;
      unitStepSize = 7;
    } else {
      pointSize = 1;
      pointHitSize = 4;
      unitStepSize = 14;
    }
  } else if (days <= 60) {
    pointSize = fullSize ? 5 : 3;
    pointHitSize = fullSize ? 7 : 5;
    unitStepSize = fullSize ? 3 : 5;
  } else if (days <= 120) {
    pointSize = fullSize ? 3 : 2;
    pointHitSize = fullSize ? 5 : 3;
    unitStepSize = fullSize ? 5 : 7;
  } else if (days <= 270) {
    pointSize = fullSize ? 2 : 1;
    pointHitSize = fullSize ? 4 : 2;
    unitStepSize = fullSize ? 7 : 14;
  } else {
    pointSize = fullSize ? 2 : 1;
    pointHitSize = fullSize ? 3 : 2;
    unitStepSize = fullSize ? 14 : 28;
  }

  switch (type) {
    case 'co':
      color = blue;
      label = (fullSize && showHmw) ? 'HMW CO' : 'TMW CO';
      limitLabel = 'Grenzwert CO';
      yAxisLabel = 'Konzentration in mg/Nm³ (trocken, 5% Sauerstoffgehalt)';
      break;
    case 'nox':
      color = green;
      label = (fullSize && showHmw) ? 'HMW NOx' : 'TMW NOx';
      limitLabel = 'Grenzwert NOx';
      yAxisLabel = 'Konzentration in mg/Nm³ (trocken, 5% Sauerstoffgehalt)';
      break;
    case 'temp':
      color = red;
      label = (fullSize && showHmw) ? 'HMW Temperatur' : 'TMW Temperatur';
      limitLabel = 'Grenzwert Temperatur';
      yAxisLabel = 'Temperatur in °C';
      break;
    default:
      color = blue;
      label = (fullSize && showHmw) ? 'HMW CO' : 'TMW CO';
      limitLabel = 'Grenzwert CO';
      yAxisLabel = 'Konzentration in mg/Nm³ (trocken, 5% Sauerstoffgehalt)';
      break;
  }

  const dataSets = {
    datasets: [
      {
        label,
        pointBackgroundColor: color,
        backgroundColor: color,
        pointRadius: pointSize,
        pointHitRadius: pointHitSize,
        type: 'scatter',
        data: chartData,
      },
      {
        label: limitLabel,
        type: 'line',
        fill: false,
        borderColor: color,
        borderWidth: 1,
        borderDash: [8, 4],
        pointStyle: 'line',
        data: limits,
      },
    ],
  };
  const options = {
    // animation: {
    //   duration: 0, // general animation time
    // },
    // hover: {
    //   animationDuration: 0, // duration of animations when hovering an item
    // },
    // responsiveAnimationDuration: 0, // animation duration after a resize,
    maintainAspectRatio: false,
    legend: {
      position: 'top',
      labels: {
        usePointStyle: true,
        fontSize: 12,
        boxWidth: 6,
      },
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Datum',
        },
        bounds: 'ticks',
        type: 'time',
        ticks: {
          max: Date.now(),
        },
        time: {
          autoSkip: false,
          unit: 'day',
          unitStepSize,
          tooltipFormat: fullSize ? 'HH:mm DD.MM' : 'DD.MM',
          displayFormats: {
            day: 'DD.MM.YYYY',
          },
        },
        position: 'bottom',
      }],
      yAxes: [{
        ticks: {
          // max: limits[1] !== undefined ? limits[1].y + 100 : 1000,
          min: 0,
          stepSize: 100,
        },
        scaleLabel: {
          display: true,
          labelString: yAxisLabel,
        },
        gridLines: {
          display: false,
        },
      }],
    },
  };
  return (
    // @ts-ignore
    <Scatter
      data={dataSets}
      options={options}
      // width={600}
      height={fullSize ? 400 : 500}
    />
  );
}

// Grenzwerte als Obere Grenze + 100 und anzeigen
// TMW Übersicht
// TMW ALLE Umdrehen bei CO genauso
export default React.memo(Chart);
