import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

import emilogBackground from '../assets/compressedBG.png';

const useStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      background: `url(${emilogBackground}) no-repeat center center fixed`,
      backgroundSize: 'cover',
      backgroundPositionY: '100%',
    },
    body: {
      background: 'none',
    },
  },
  card: {
    marginTop: theme.spacing(8),
    borderRadius: 8,
  },
  paper: {
    padding: 20,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
  },
  link: {
    marginTop: theme.spacing(1),
    fontSize: 20,
  },
}));

function Error() {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography variant="h4">
          Error
        </Typography>
        <Typography>
          Bei der Anmeldung ist etwas schiefgelaufen. Bitte melden Sie sich unter info@emilog-portal.de.
        </Typography>
        <Link className={classes.link} to="/">Zurück zur Anmeldeseite</Link>
      </div>
    </Container>
  );
}

export default Error;
