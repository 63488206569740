/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { useHistory, Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Auth } from '@aws-amplify/auth';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, lighten } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ExitToApp from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { grey } from '@material-ui/core/colors';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import AuthContext from '../context/AuthContext';
import banner from '../assets/banner.png';
// import SettingsDialog from './UserTable/SettingsDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    height: 85,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : 'white',
    background: `no-repeat url(${banner})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    borderBottom: theme.palette.type === 'dark' ? '2px solid #424242' : '1px solid lightgray',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      height: 60,
      backgroundPosition: '40% center',
    },
  },
  appbarBoxShadow: {
    boxShadow: 'none',
  },
  headLine: {
    fontWeight: '700',
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
    },
  },
  icon: {
    fontSize: 36,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  email: {
    color: lighten('#000000', 0.5),
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  container: {
    // maxWidth: '100%',
  },
  navigation: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    right: 0,
  },
  navButton: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  active: {
    color: theme.palette.primary.main,
  },
  speedDial: {
    position: 'absolute',
    top: 8,
    right: 16,
  },
  fabRoot: {
    width: 40,
    height: 40,
    backgroundColor: 'white',
    color: 'black',
    borderRadius: 'none',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: grey[200],
    },
  },
}));

export default function MainLayout({ children }) {
  MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
  };
  const mobile = useMediaQuery('(max-width:600px)');

  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const activeIcon = clsx(classes.icon, classes.active);
  const { currentUser } = useContext(AuthContext);

  function logout() {
    currentUser.loggedIn = false;
    Auth.signOut();
    history.push('/login');
  }

  const actions = [
    { icon: <HomeIcon />, name: 'Dashboard', path: '/' },
    { icon: <SettingsIcon />, name: 'Einstellungen', path: '/einstellungen' },
    { icon: <ExitToApp />, name: 'Abmelden', path: 'logout' },
  ];

  function handleClick(path) {
    setOpen(false);
    if (path === 'logout') {
      logout();
    } else {
      history.push(path);
    }
  }

  function handleOpen(event, reason) {
    if (reason === 'toggle') {
      setOpen(true);
    }
  }

  function handleClose(event, reason) {
    if (reason === 'toggle') {
      setOpen(false);
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="static" className={classes.appbar}>
        <Typography variant="h3" color="primary" align="center" className={classes.headLine}>
          EMI-VIEW Kundenportal
        </Typography>
        {mobile === true ? (
          <SpeedDial
            className={classes.speedDial}
            ariaLabel="Menüelemente"
            onOpen={handleOpen}
            onClose={handleClose}
            open={open}
            direction="down"
            icon={(
              <SpeedDialIcon
                icon={(
                  <MenuIcon />
                )}
                openIcon={(
                  <CloseIcon />
                )}
              />
            )}
            classes={{
              fab: classes.fabRoot,
            }}
          >
            {actions.map(({ name, icon, path }) => (
              <SpeedDialAction
                aria-label={name}
                key={name}
                icon={icon}
                tooltipTitle={name}
                onClick={() => handleClick(path)}
              />
            ))}
          </SpeedDial>
        ) : (
          <div className={classes.navigation}>
            <Typography className={classes.email}>{currentUser.user.attributes.email}</Typography>
            <Tooltip title="Dashboard">
              <Link to="/" className={classes.navButton}>
                <IconButton>
                  <HomeIcon size="inherit" className={pathname === '/' ? activeIcon : classes.icon} />
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip title="Einstellungen">
              <Link to="/einstellungen" className={classes.navButton}>
                <IconButton>
                  <SettingsIcon size="inherit" className={pathname === '/einstellungen' ? activeIcon : classes.icon} />
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip title="Ausloggen">
              <IconButton onClick={logout} className={classes.logoutButton}>
                <ExitToApp size="inherit" className={classes.icon} />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </AppBar>
      <main className={classes.content}>
        <Container className={classes.container} maxWidth="xl">
          {children}
        </Container>
      </main>
    </div>
  );
}
