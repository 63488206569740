import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

import { green } from '@material-ui/core/colors';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';

import { Auth } from '@aws-amplify/auth';

import FormInput from '../FormInput';
import LoaderButton from '../LoaderButton';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 32,
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: 16,
  },
  success: {
    color: green[500],
    fontSize: '0.9rem',
  },
}));

function Settings() {
  const classes = useStyles();

  const [password, setPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');

  const [isSending, setIsSending] = useState(false);
  const [success, setSuccess] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [validLength, setValidLength] = useState(true);

  const [wrongPassword, setWrongPassword] = useState(false);

  function validateLength() {
    if (password.length < 8) {
      return false;
    }
    return true;
  }
  function validatePassword() {
    if (password === confirmPassword) {
      return true;
    }
    return false;
  }

  function togglePassword() {
    setShowPassword(!showPassword);
  }

  async function submitPasswordChange(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setWrongPassword(false);
    try {
      if (validateLength() === false) {
        setValidLength(false);
        return;
      }
      const currentUser = await Auth.currentAuthenticatedUser();
      setIsSending(true);
      await Auth.changePassword(
        currentUser,
        oldPassword,
        password,
      );
      setIsSending(false);
      setSuccess(true);
      setPassword('');
      setOldPassword('');
      setConfirmPassword('');
      setValidLength(true);
    } catch (err: any) {
      setIsSending(false);
      setValidLength(true);
      if (err.message === 'Incorrect username or password.' || err.code === 'InvalidParameterException') {
        setWrongPassword(true);
      }
      // console.log(err);
    }
  }

  const PasswordToggle = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={togglePassword}
      >
        {!showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <Card>
      <form onSubmit={submitPasswordChange}>
        <CardHeader
          title="Passwort ändern"
        />
        <Divider />
        <CardContent>
          <FormInput
            id="oldPassword"
            label="Altes Passwort"
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setOldPassword(e.target.value)}
            error={wrongPassword}
            helperText={wrongPassword ? 'Passwort ist falsch' : ''}
            InputProps={{
              endAdornment: PasswordToggle,
            }}
            value={oldPassword}
            size="small"
          />
          <FormInput
            id="password"
            label="Passwort"
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
            helperText={!validLength ? 'Passwort muss mindestens 8 Zeichen lang sein' : ''}
            error={!validLength}
            InputProps={{
              endAdornment: PasswordToggle,
            }}
            value={password}
            size="small"
          />
          <FormInput
            id="confirmPassword"
            label="Passwort wiederholen"
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!validatePassword()}
            helperText={validatePassword() ? '' : 'Passwörter stimmen nicht überein'}
            InputProps={{
              endAdornment: PasswordToggle,
            }}
            value={confirmPassword}
            size="small"
          />
        </CardContent>
        <Divider />
        <CardActions className={classes.cardActions}>
          {success && (
            <Typography className={classes.success}>Passwort erfolgreich geändert</Typography>
          )}
          <LoaderButton
            isLoading={isSending}
            text="Absenden"
          />
        </CardActions>
      </form>
    </Card>

  );
}

export default Settings;
