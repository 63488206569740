import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { grey } from '@material-ui/core/colors';
import { API } from '@aws-amplify/api';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
  },
  buttonProgress: {
    color: grey[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface AddDeviceDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  devices: Device[]
}

function AddDeviceDialog({
  open, onClose, onSubmit, devices,
}: AddDeviceDialogProps) {
  const classes = useStyles();

  const [serialNumber, setSerialNumber] = useState('');
  const [description, setDescription] = useState('');

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [loading, setLoading] = useState(false);

  function checkSerialNumber() {
    let exists = false;
    devices.forEach((device) => {
      if (serialNumber === device.serialNumber) {
        exists = true;
      }
    });

    return exists;
  }

  function handleClose() {
    setError(false);
    setErrorText('');
    onClose();
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setError(false);
    setErrorText('');
    const alreadyExists = checkSerialNumber();
    if (alreadyExists) {
      setErrorText('Seriennummer ist bereits registriert');
      setError(true);
      return;
    }
    try {
      setLoading(true);
      await API.post('emilog', 'registerDevice', { body: { description, serialNumber } });
      onSubmit();
      setLoading(false);
      setError(false);
    } catch (err: any) {
      setLoading(false);
      if (err.response.status === 404) {
        setErrorText('Seriennummer existiert nicht');
        setError(true);
        return;
      }
      setErrorText('Etwas ist schiefgelaufen');
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Messgerät hinzufügen</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>
            Um ein neues Messgerät hinzuzufügen, geben Sie bitte die Seriennummer und eine
            individuelle Bezeichnung an.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                id="serialNumber"
                label="Seriennummer"
                type="text"
                onChange={(e) => setSerialNumber(e.target.value)}
                required
                autoFocus
                error={error}
                helperText={errorText}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                id="bezeichnung"
                label="Bezeichnung"
                type="text"
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Abbrechen</Button>
          <div className={classes.wrapper}>
            <Button type="submit" color="primary">Abschicken</Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
}

// export default React.memo(AddDeviceDialog);
export default AddDeviceDialog;
