import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import { API } from '@aws-amplify/api';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import LoaderButton from '../components/LoaderButton';
import FormInput from '../components/FormInput';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();
  // const history = useHistory();

  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [validLength, setValidLength] = useState(true);

  const [codeSent, setCodeSent] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);

  const [isConfirming, setIsConfirming] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [open, setOpen] = useState(false);

  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('Etwas ist schiefgelaufen');

  function validateEmail() {
    return (
      email.length > 0
    );
  }

  function validateFields() {
    return (
      code.length > 0
      && password.length > 0
      && password === confirmPassword
    );
  }

  function validatePassword() {
    if (password === confirmPassword) {
      return true;
    }
    return false;
  }

  function validateLength() {
    if (password.length < 8) {
      return false;
    }
    return true;
  }

  async function handleReset(event: any) {
    setHelperText('Etwas ist schiefgelaufen');
    setError(false);
    event.preventDefault();
    setIsSendingCode(true);
    try {
      await API.get('emilog', 'checkIfMailExists', {
        queryStringParameters: {
          email: email.toLowerCase(),
        },
      });
      await Auth.forgotPassword(email.toLowerCase());
      setCodeSent(true);
      setOpen(true);
    } catch (err: any) {
      setIsSendingCode(false);
      if (err.response.status === 404) {
        setHelperText('Account mit dieser E-Mailadresse existiert nicht');
      }
      setError(true);
    }
  }

  async function handleConfirm(event: any) {
    event.preventDefault();
    if (validateLength() === false) {
      setValidLength(false);
      return;
    }
    setIsConfirming(true);

    try {
      await Auth.forgotPasswordSubmit(
        email,
        code,
        password,
      );
      setConfirmed(true);
    } catch (err) {
      setError(true);
      setIsConfirming(false);
    }
  }

  function togglePassword() {
    setShowPassword(!showPassword);
  }

  const PasswordToggle = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={togglePassword}
      >
        {!showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  function renderResetForm() {
    return (
      <form className={classes.form} onSubmit={handleReset}>

        <FormInput
          id="email"
          label="E-Mail"
          autoComplete="email"
          autoFocus
          error={error}
          helperText={error ? helperText : ''}
          onChange={(e: any) => setEmail(e.target.value)}
          value={email}
        />
        <LoaderButton
          isLoading={isSendingCode}
          disabled={!validateEmail()}
          text="Passwort zurücksetzen"
          fullWidth
        />
        <Link to="/login" style={{ textDecoration: 'inherit' }}>
          Zurück zum Login
        </Link>
      </form>
    );
  }

  function renderConfirmationForm() {
    return (
      <form className={classes.form} onSubmit={handleConfirm}>

        <FormInput
          id="code"
          label="Code"
          onChange={(e: any) => setCode(e.target.value)}
          value={code}
          autoFocus
        />
        <FormInput
          id="password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e: any) => setPassword(e.target.value)}
          label="Passwort"
          helperText={validLength === false ? 'Passwort muss mindestens 8 Zeichen lang sein' : ''}
          error={validLength === false}
          InputProps={{
            endAdornment: PasswordToggle,
          }}
        />

        <FormInput
          label="Passwort wiederholen"
          type={showPassword ? 'text' : 'password'}
          id="confirmPassword"
          onChange={(e: any) => setConfirmPassword(e.target.value)}
          error={!validatePassword()}
          helperText={validatePassword() ? '' : 'Passwörter stimmen nicht überein'}
          InputProps={{
            endAdornment: PasswordToggle,
          }}
        />
        <LoaderButton
          isLoading={isConfirming}
          text="Passwort ändern"
          disabled={!validateFields()}
          fullWidth
        />
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>
            E-Mail versendet!
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Überprüfen Sie bitte Ihren Posteingang und geben Sie den Code aus der E-Mail
              im Eingabefeld ein.
              Falls Sie unsere E-Mail nicht in Ihrem Posteingang finden, prüfen Sie bitte
              Ihren Spam-Ordner.
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </form>
    );
  }

  function renderSuccess() {
    return (
      <>
        <Typography>Ihr Passwort wurde erfolgreich geändert.</Typography>
        <Link to="/login">
          Klicken Sie hier um sich anzumelden!
        </Link>
      </>
    );
  }

  return (
    <>
      <Avatar className={classes.avatar}>
        {!confirmed ? (
          <LockOutlinedIcon />
        ) : (
          <CheckCircleOutlineIcon />
        )}
      </Avatar>
      <Typography component="h1" variant="h5">
        Passwort zurücksetzen
      </Typography>
      {!codeSent
        ? renderResetForm()
        : !confirmed
          ? renderConfirmationForm()
          : renderSuccess()}
    </>
  );
}
