import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { green } from '@material-ui/core/colors';
import { API } from '@aws-amplify/api';
import { Auth } from '@aws-amplify/auth';
import AuthContext from '../../context/AuthContext';
import FormInput from '../FormInput';
import LoaderButton from '../LoaderButton';
import Feedback from '../Feedback';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 32,
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: 16,
  },
  success: {
    color: green[500],
    fontSize: '0.9rem',
  },
}));

function ChangeEmail() {
  const classes = useStyles();
  const { authenticate } = useContext(AuthContext);

  const [newEmail, setNewEmail] = useState('');
  const [code, setCode] = useState('');
  const [open, setOpen] = useState(false);

  const [codeSent, setCodeSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [wrongCode, setWrongCode] = useState(false);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      setError(false);
      setEmailExists(false);
      setIsSending(true);
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, { email: newEmail });
      setOpen(true);
      setCodeSent(true);
    } catch (err: any) {
      if (err.code === 'AliasExistsException') {
        setEmailExists(true);
      } else {
        setError(true);
      }
      setIsSending(false);
    }
  }

  async function handleConfirm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsConfirming(true);

    try {
      setWrongCode(false);
      await Auth.verifyCurrentUserAttributeSubmit('email', code);
      await API.post('emilog', 'changeUserMail', { body: { email: newEmail } });
      setSuccess(true);
      setIsConfirming(false);
      setTimeout(() => {
        setCodeSent(false);
      }, 5000);
      authenticate();
    } catch (err: any) {
      if (err.code === 'CodeMismatchException') {
        setWrongCode(true);
      }
      setIsConfirming(false);
    }
  }

  function renderResetForm() {
    return (
      <form onSubmit={handleSubmit}>
        <CardContent>
          <FormInput
            id="email"
            label="E-Mail"
            autoComplete="email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewEmail(e.target.value)}
            value={newEmail}
          />
        </CardContent>
        <Divider />
        <CardActions className={classes.cardActions}>
          <Feedback
            success={success}
            successText="E-Mail-Adresse erfolgreich geändert"
            error={error || emailExists}
            errorText={emailExists ? 'Ein Account mit dieser E-Mail-Adresse existiert bereits' : 'Etwas ist schiefgelaufen'}
          />
          <LoaderButton
            isLoading={isSending}
            text="Absenden"
            fullWidth={false}
          />
        </CardActions>
      </form>
    );
  }
  function renderConfirmForm() {
    return (
      <form onSubmit={handleConfirm}>
        <CardContent>
          <FormInput
            id="code"
            label="Code"
            autoFocus
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
            value={code}
          />
        </CardContent>
        <Divider />
        <CardActions className={classes.cardActions}>
          <Feedback
            success={success}
            successText="E-Mail-Adresse erfolgreich geändert"
            error={wrongCode}
            errorText="Code ist nicht korrekt"
          />
          <LoaderButton
            isLoading={isConfirming}
            disabled={code.length < 6}
            text="Absenden"
            fullWidth={false}
          />
        </CardActions>
      </form>
    );
  }

  return (
    <>
      <Card>
        <CardHeader
          title="Login E-Mail-Adresse ändern"
        />
        <Divider />
        {!codeSent
          ? renderResetForm()
          : renderConfirmForm()}
      </Card>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          E-Mail versendet!
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Überprüfen Sie bitte Ihren Posteingang und geben Sie den Code aus der E-Mail
            im Eingabefeld ein.
            Falls Sie unsere E-Mail nicht in Ihrem Posteingang finden, prüfen Sie bitte
            Ihren Spam-Ordner.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>

  );
}

export default ChangeEmail;
