import React from 'react';
import TextField from '@material-ui/core/TextField';

interface FormInputProps {
  id: string
  label: string
  type?: string
  autoFocus?: boolean
  InputProps?: any
  helperText?: string
  error?: boolean
  autoComplete?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => React.SetStateAction<any>
  [key: string]: any
}

export default function FormInput({
  id, label, type, autoFocus,
  InputProps, helperText, error, autoComplete, ...props
}: FormInputProps) {
  return (
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      id={id}
      label={label}
      name={id}
      autoFocus={autoFocus}
      type={type}
      InputProps={InputProps}
      helperText={helperText}
      error={error}
      {...props}
    />
  );
}
