/* eslint-disable */
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';

import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';
import { AuthProvider } from './context/AuthContext';
import theme from './theme';


import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Error from './pages/Error';
import Register from './pages/Register';
import Einstellungen from './pages/Einstellungen';
import ForgotPassword from './pages/ForgotPassword';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AuthProvider>
          <Switch>
            <PublicRoute exact path="/login" component={Login} />
            <PublicRoute exact path="/register" component={Register} />
            <PublicRoute exact path="/error" component={Error} />
            <PublicRoute exact path="/forgotPassword" component={ForgotPassword} />
            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute exact path="/einstellungen" component={Einstellungen} />
            <Redirect to="/" />
          </Switch>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
