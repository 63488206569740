import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import ChangePassword from '../components/Einstellungen/ChangePassword';
import ChangeEmail from '../components/Einstellungen/ChangeEmail';
import AddReceivers from '../components/Einstellungen/AddReceivers';
import NetworkSettings from '../components/Einstellungen/NetworkSettings';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    height: '95vh',
    width: '75vw',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexWrap: 'nowrap',
    },
  },
  card: {
    [theme.breakpoints.up('lg')]: {
      width: '50%',
      padding: theme.spacing(1),
    },
    width: '100%',
    paddingBottom: theme.spacing(1),
  },
  fixedHeight: {
    [theme.breakpoints.up('lg')]: {
      width: '50%',
      padding: theme.spacing(1),
    },
    paddingBottom: theme.spacing(1),
    height: 420,
  },
}));

export default function Einstellungen() {
  const classes = useStyles();

  return (
    <Container maxWidth="xl" className={classes.container}>
      <div className={classes.card}>
        <ChangePassword />
      </div>
      <div className={classes.card}>
        <ChangeEmail />
      </div>
      <div className={classes.card}>
        <NetworkSettings />
      </div>
      <div className={classes.fixedHeight}>
        <AddReceivers />
      </div>
    </Container>
  );
}
