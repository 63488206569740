import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import { ReactComponent as Abgelaufen } from '../../assets/abgelaufen.svg';

interface ExpiredDialogProps {
  open: boolean
  onClose: any
  serialNumber: string
}

export default function ExpiredDialog({ open, onClose, serialNumber }: ExpiredDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Abonnement abgelaufen</DialogTitle>
      <DialogContent style={{ display: 'inline-flex', flexDirection: 'column', alignContent: 'flex-start' }}>
        <Grid container>
          <Grid item lg={7}>
            <Typography>
              Ihr Messbericht-Abonnement für das Messgerät mit der Seriennummer
              {' '}
              {serialNumber}
              {' '}
              ist abgelaufen.

              Klicken Sie auf folgenden Link,
              um den digitalen Messbericht für ein weiteres Jahr zu erhalten:
            </Typography>
          </Grid>
          <Grid item lg={5} style={{ marginTop: -30 }}>
            <Abgelaufen />
          </Grid>
        </Grid>

        <Button variant="outlined" style={{ margin: 16, alignSelf: 'center' }}>
          <Link target="_blank" rel="noreferrer" href="https://www.bhkwteile.de/emissionsmessung-oxid-1/kontinuierliche-ueberwachung/digitaler-messbericht-emi-log.html">
            Abonnement verlängern!
          </Link>
        </Button>
        <Typography>
          Hinweis: Halten Sie die Seriennummer Ihres Messgeräts zur Eingabe bereit.
        </Typography>

      </DialogContent>
      <DialogActions>
        {/* @ts-ignore */}
        <Button onClick={onClose}>Schließen</Button>
      </DialogActions>
    </Dialog>
  );
}
