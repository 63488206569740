import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { green, red, grey } from '@material-ui/core/colors';
import { API } from '@aws-amplify/api';
import isEqual from 'react-fast-compare';

import { Typography } from '@material-ui/core';
import { ChartDropdown } from './ChartDropdown';
import AuthContext from '../../context/AuthContext';
import CsvDialog from './CsvDialog';
import BerichtDialog from './BerichtDialog';
import ExpiredDialog from './ExpiredDialog';
import NotActiveDialog from './NotActiveDialog';
import ConfirmRemoveDialog from './ConfirmRemoveDialog';

import formatStatus from '../../utils/formatStatus';
import convertTimestamp from '../../utils/convertTimestamp';

const useStyles = makeStyles(() => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  editIcon: {
    position: 'absolute',
    top: -20,
    right: -44,
  },
  checkIcon: {
    margin: 4,
    cursor: 'pointer',
    '&:hover': {
      color: green[500],
    },
  },
  clearIcon: {
    margin: 4,
    cursor: 'pointer',
    '&:hover': {
      color: red[500],
    },
  },
  selectedRow: {
    backgroundColor: `${grey[300]}!important`,
  },
}));

interface RowProps {
  data: DeviceWithShadow
  editFinished: Function
  onRemove: Function
  requestedLiveValuesOnce: boolean
}

function Row({
  data: {
    serialNumber, motorennummer, bezeichnung, co_m, nox, temp, status,
    timestamps, betriebsstunden, ausfallstunden, messberichtBis, o2_f, O2_a, isEmicon,
  }, editFinished, onRemove, requestedLiveValuesOnce,
}: RowProps) {
  // console.log({
  //   nox, temp, co_m, serialNumber, timestamps,
  // });
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);

  const [newDescription, setNewDescription] = useState(bezeichnung);

  const [showChart, setShowChart] = useState(false);

  const [editingRow, setEditingRow] = useState(false);

  const [csvOpen, setCsvOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const [expiredOpen, setExpiredOpen] = useState(false);
  const [notActiveOpen, setNotActiveOpen] = useState(false);

  const [deleteOpen, setDeleteOpen] = useState(false);

  const [menuAnchor, setMenuAnchor] = useState(null);

  function renderValue(value: number | undefined | null) {
    if (typeof value === 'undefined' || value === null) {
      return '-';
    }
    let o2Condition = false;
    if (o2_f && O2_a) {
      o2Condition = o2_f > O2_a;
    }
    const hideValue = status === 'offline' || status === 'passiv' || o2Condition;
    if (hideValue || !Number.isFinite(value) || typeof value === 'undefined') {
      return '-';
    }
    return Math.round(value);
  }

  function handleOpenMenu(event: any) {
    if (editingRow) {
      setEditingRow(false);
    } else {
      setMenuAnchor(event.currentTarget);
    }
  }

  function handleCloseMenu() {
    setMenuAnchor(null);
  }

  function handleCsvDialog(event: any) {
    event.stopPropagation();
    setCsvOpen(true);
  }

  function handleBerichtDialog(event: any) {
    event.stopPropagation();
    if (currentUser?.user.attributes.email === 'messe@emission-partner.de' ||
      currentUser?.user.attributes.email === 'user@elektrosolid.de' ||
      currentUser?.user.attributes.email === 'abgasmanagement@agrarservice-lass.de') {
      setReportOpen(true);
      return;
    }

    if (Math.round(Date.now() / 1000) > (messberichtBis ?? Infinity)) {
      setExpiredOpen(true);
    } else if (typeof messberichtBis === 'undefined') {
      setNotActiveOpen(true);
    } else {
      setReportOpen(true);
    }
  }

  async function confirmEdit(event: any, selectedSerial: string) {
    event.stopPropagation();
    setEditingRow(false);
    await API.post('emilog', 'updateDescription', { body: { description: newDescription, serialNumber: selectedSerial } });
    editFinished(newDescription, selectedSerial);
  }

  function handleEditRow() {
    setMenuAnchor(null);
    setEditingRow(!editingRow);
    setNewDescription(bezeichnung);
  }

  function handleDelete() {
    setMenuAnchor(null);
    setDeleteOpen(true);
  }

  async function handleExpand() {
    if (!showChart) {
      setShowChart(true);
    } else {
      setShowChart(false);
    }
  }

  return (
    <>
      <TableRow
        tabIndex={-1}
        key={serialNumber}
        className={classes.root}
        selected={editingRow}
        classes={{
          selected: classes.selectedRow,
        }}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={handleExpand}>
            {showChart ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {serialNumber}
        </TableCell>
        <TableCell>
          {motorennummer ? motorennummer : '-'}
        </TableCell>
        <TableCell align="left" width="20%">
          {editingRow ? (
            // eslint-disable-next-line
            <div onClick={((event) => (event.stopPropagation()))}>
              <TextField
                style={{ width: 164 }}
                onChange={(e) => setNewDescription(e.target.value)}
                value={newDescription}
              />
              <Tooltip title="Absenden">
                <CheckIcon className={classes.checkIcon} fontSize="small" onClick={(event) => confirmEdit(event, serialNumber)} />
              </Tooltip>
            </div>
          ) : (
            <span>
              {bezeichnung ?? '-'}
            </span>
          )}
        </TableCell>

        <>
          <Tooltip
            title={timestamps?.status?.timestamp ? `Status: ${convertTimestamp(timestamps?.status?.timestamp) ?? '-'}` : ''}
          >
            <TableCell width="8%" align="left"><span>{formatStatus(status) ?? '-'}</span></TableCell>

          </Tooltip>

          <TableCell align="right">
            {renderValue(nox)}
          </TableCell>
          <TableCell align="right">
            {requestedLiveValuesOnce ? renderValue(co_m) : '-'}
          </TableCell>
          <TableCell align="right">
            {renderValue(temp)}
          </TableCell>
          <TableCell align="right">
            {betriebsstunden ?? '-'}
          </TableCell>
          <TableCell align="right">
            {ausfallstunden ?? '-'}
          </TableCell>
        </>

        <TableCell align="center">
          <Tooltip title="CSV herunterladen">
            <span>
              <IconButton
                onClick={handleCsvDialog}
              >
                <ArrowDownward />
              </IconButton>
            </span>
          </Tooltip>
        </TableCell>

        <TableCell align="center">
          <Tooltip title="Bericht herunterladen">
            <span>
              <IconButton
                onClick={handleBerichtDialog}
              >
                <ArrowDownward />
              </IconButton>
            </span>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={editingRow ? 'Abbrechen' : 'Zeile bearbeiten'}>
            <IconButton aria-label="edit item" size="small" onClick={handleOpenMenu}>
              {editingRow ? <ClearIcon /> : <MoreVertIcon />}
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      {/* Ab hier Dropdown */}

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <ChartDropdown
            showChart={showChart}
            serialNumber={serialNumber}
            isEmicon={isEmicon}
          />
        </TableCell>
      </TableRow>


      <CsvDialog
        serialNumber={serialNumber}
        open={csvOpen}
        onClose={() => setCsvOpen(false)}
        isEmicon={isEmicon}
      />
      <BerichtDialog
        serialNumber={serialNumber}
        open={reportOpen}
        onClose={() => setReportOpen(false)}
        isEmicon={isEmicon}
      />
      <ExpiredDialog
        serialNumber={serialNumber}
        open={expiredOpen}
        onClose={() => setExpiredOpen(false)}
      />
      <NotActiveDialog
        serialNumber={serialNumber}
        open={notActiveOpen}
        onClose={() => setNotActiveOpen(false)}
      />
      <ConfirmRemoveDialog
        serialNumber={serialNumber}
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        description={bezeichnung}
        onSubmit={onRemove}
      />
      <Menu
        id="simple-menu"
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleEditRow}>
          {/* <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon> */}
          <Typography variant="inherit">Bearbeiten</Typography>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          {/* <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon> */}
          <Typography variant="inherit">Entfernen</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default React.memo(Row, isEqual);
// export default Row;
