import React, { useState } from 'react';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { grey } from '@material-ui/core/colors';
import { API } from '@aws-amplify/api';

import LoaderButton from '../LoaderButton';
import Feedback from '../Feedback';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
  },
  buttonProgress: {
    color: grey[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function ConfirmRemoveDialog({
  open, onClose, onSubmit, serialNumber, description,
}) {
  const classes = useStyles();
  ConfirmRemoveDialog.propTypes = {
    open: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired,
    onSubmit: propTypes.func.isRequired,
    serialNumber: propTypes.string,
    description: propTypes.string,
  };

  ConfirmRemoveDialog.defaultProps = {
    serialNumber: '',
    description: '',
  };

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [loading, setLoading] = useState(false);

  function handleClose() {
    setError(false);
    setErrorText('');
    onClose();
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setError(false);
    setErrorText('');
    try {
      setLoading(true);
      await API.post('emilog', 'removeDevice', { body: { serialNumber } });
      onSubmit(serialNumber);
      onClose();
      setLoading(false);
      setError(false);
    } catch (err) {
      setLoading(false);
      setErrorText('Etwas ist schiefgelaufen');
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Messgerät entfernen</DialogTitle>
      <DialogContent>
        <DialogContentText>

          Wollen Sie das Messgerät
          {' '}
          {serialNumber}
          {' '}
          (
          {description}
          ) aus Ihrem Portal entfernen?
          <br />
          Die Seriennummer kann jederzeit dem Portal wieder hinzugefügt werden.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Feedback error={error} errorText={errorText} />
        <Button onClick={onClose}>Abbrechen</Button>
        <div className={classes.wrapper}>
          <LoaderButton
            text="Bestätigen"
            isLoading={loading}
            onClick={handleSubmit}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}

// export default React.memo(AddDeviceDialog);
export default ConfirmRemoveDialog;
