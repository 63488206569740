import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircleOutline, Info, Search as SearchIcon } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddDeviceDialog from './AddDeviceDialog';
import InfoDialog from './InfoDialog';
import LoaderButton from '../LoaderButton';
import { convertTimer } from '../../utils/convertTimestamp';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  addEmilog: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
    },
  },
}));

interface DashboardTableToolbarProps {
  devices: Device[]
  onNew: Function
  setFilter: Function
  requestLiveValues: () => void
  setLoadingLiveData: React.Dispatch<React.SetStateAction<boolean>>
  loadingLiveData: boolean
  liveDataDuration?: number
}

function DashboardTableToolbar({
  devices, onNew, setFilter, requestLiveValues, loadingLiveData, setLoadingLiveData, liveDataDuration,
}: DashboardTableToolbarProps) {
  const mobile = useMediaQuery('(max-width:600px)');

  const classes = useToolbarStyles();
  const [open, setOpen] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleRequestLiveData() {
    setLoadingLiveData(true);
    requestLiveValues();
  }

  const handleSubmit = () => {
    handleClose();
    onNew();
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  return (
    <Toolbar
      className={classes.root}
    >
      <div>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <SearchIcon />
          </Grid>
          <Grid item>
            <TextField
              label="Suche"
              onChange={handleSearch}
              size="small"
            />
          </Grid>
          <Grid item style={{ marginLeft: mobile ? 0 : 16, paddingBottom: 0 }}>
            <LoaderButton
              isLoading={loadingLiveData}
              text="Echtzeit-Messwerte anfordern"
              variant="outlined"
              onClick={handleRequestLiveData}
              noMargin
            />
            <Tooltip title="Informationen anzeigen">

              <IconButton onClick={() => setInfoDialogOpen(true)}>
                <Info />
              </IconButton>
            </Tooltip>

          </Grid>
          {liveDataDuration ? (
            <Grid item style={{ marginTop: 6, alignSelf: 'center' }}>
              Werte sind aktuell für
              {' '}
              {convertTimer(liveDataDuration)}
            </Grid>
          ) : liveDataDuration === 0 ? (
            <Grid item style={{ marginTop: 6, alignSelf: 'center' }}>
              Werte sind nicht aktuell
            </Grid>
          ) : (
            null
          )}
        </Grid>
      </div>

      <div className={classes.addEmilog}>
        <Typography color="inherit" component="span">Messgerät hinzufügen</Typography>
        <Tooltip title="Messgerät hinzufügen">
          <IconButton aria-label="hinzufügen" onClick={handleOpen} style={{ padding: 12 }}>
            <AddCircleOutline style={{ fontSize: '2rem' }} />
          </IconButton>
        </Tooltip>
      </div>

      <AddDeviceDialog
        open={open}
        onClose={handleClose}
        devices={devices}
        onSubmit={handleSubmit}
      />
      <InfoDialog
        open={infoDialogOpen}
        onClose={() => setInfoDialogOpen(false)}
      />
    </Toolbar>
  );
}

// export default React.memo(DashboardTableToolbar);
export default DashboardTableToolbar;
