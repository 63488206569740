/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';

import { API } from '@aws-amplify/api';
import { Auth } from '@aws-amplify/auth';

import LoaderButton from '../components/LoaderButton';
import FormInput from '../components/FormInput';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
}));

export default function Register() {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [registered, setRegistered] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = React.useState(false);

  const [validLength, setValidLength] = useState<boolean | undefined>(undefined);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState('');

  const [errorText, setErrorText] = useState('');
  const [error, setError] = useState(false);

  function validateFields() {
    return (
      serialNumber.length > 0
      && email.length > 0
      && password.length > 0
      && password === confirmPassword
    );
  }

  function validateLength() {
    if (password.length < 8) {
      return false;
    }
    return true;
  }

  function validatePassword() {
    if (password === confirmPassword) {
      return true;
    }
    return false;
  }

  function handleClose() {
    setRegistered(false);
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    setLoading(true);
    event.preventDefault();
    if (validateLength() === false) {
      setValidLength(false);
      return;
    }
    setValidLength(true);
    try {
      await API.get('emilog', 'checkNumber', { queryStringParameters: { serialNumber } });
    } catch (err: any) {
      console.log(err);
      if (err.response.status === 404) {
        setErrorText('Seriennummer existiert nicht');
        setLoading(false);
        setError(true);
        return;
      }
      if (err.response.status === 401) {
        setErrorText('Keine Berechtigung');
        setLoading(false);
        setError(true);
        return;
      }
    }

    try {
      setEmailError(false);
      setEmailErrorText('');
      await Auth.signUp({
        username: email.toLowerCase(),
        password,
        clientMetadata:
        {
          serialNumber,
        },

      });
      setLoading(false);
      setRegistered(true);
      return;
    } catch (err: any) {
      if (err.code === 'UsernameExistsException') {
        setEmailError(true);
        setEmailErrorText('Nutzer mit dieser E-Mail existiert bereits');
      } else {
        setEmailErrorText('Etwas ist schiefgelaufen');
      }
      setLoading(false);
    }
  }

  function togglePassword() {
    setShowPassword(!showPassword);
  }

  const PasswordToggle = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={togglePassword}
      >
        {!showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Registrieren
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <FormInput
          id="serialNumber"
          label="Seriennummer"
          name="serialNumber"
          onChange={(e) => setSerialNumber(e.target.value)}
          error={error}
          helperText={errorText}
        />
        <FormInput
          id="email"
          onChange={(e) => setEmail(e.target.value)}
          label="E-Mail Adresse"
          autoFocus
          error={emailError}
          helperText={emailErrorText}
        />
        <FormInput
          id="password"
          label="Passwort"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setPassword(e.target.value)}
          helperText={validLength === false ? 'Passwort muss mindestens 8 Zeichen lang sein' : ''}
          error={validLength === false}
          InputProps={{
            endAdornment: PasswordToggle,
          }}
        />
        <FormInput
          id="confirmPassword"
          label="Passwort wiederholen"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setConfirmPassword(e.target.value)}
          error={!validatePassword()}
          helperText={validatePassword() ? '' : 'Passwörter stimmen nicht überein'}
          InputProps={{
            endAdornment: PasswordToggle,
          }}
        />
        <LoaderButton
          isLoading={loading}
          text="Registrieren"
          disabled={!validateFields()}
          fullWidth
        />
        <Grid container justify="flex-end">
          <Grid item>
            <RouterLink to="/login" style={{ textDecoration: 'inherit' }}>
              Schon einen Account erstellt? Anmelden
            </RouterLink>
          </Grid>
        </Grid>
      </form>
      <Dialog open={registered} onClose={handleClose}>
        <DialogTitle>
          Bestätigungsmail gesendet!
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Überprüfen Sie bitte Ihren Posteingang und folgen Sie den Anweisungen.
            Falls Sie unsere E-Mail nicht in Ihrem Posteingang finden, prüfen Sie bitte
            Ihren Spam-Ordner.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
