/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import { API } from '@aws-amplify/api';
import { Auth } from '@aws-amplify/auth';
import LoaderButton from '../components/LoaderButton';
import FormInput from '../components/FormInput';

const uppercaseUsers = ['Tele66@gmx.de', 'Thomas.Vinzelberg@swsn.de', 'Schick.timo@gmx.de', 'LWB@Zachmann-Landwirtschaft.de'];

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authenticating, setAuthenticating] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setAuthenticating(true);
    try {
      let signInMail = email.toLowerCase();
      if (uppercaseUsers.includes(email)) {
        signInMail = email;
      }
      await Auth.signIn({ username: signInMail, password });
      await API.post('emilog', 'attachPolicy', {});
      history.push('/');
    } catch (e: any) {
      if (e.code === 'UserNotConfirmedException') {
        setErrorText('Email ist noch nicht verifiziert');
      } else {
        setErrorText('Passwort oder Email ist falsch');
      }
      console.log(e);
      setPassword('');
      setError(true);
      setAuthenticating(false);
    }
  }

  function togglePassword() {
    setShowPassword(!showPassword);
  }

  const PasswordToggle = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={togglePassword}
      >
        {!showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Anmelden
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <FormInput
          id="email"
          onChange={(e) => setEmail(e.target.value)}
          label="E-Mail"
        />
        <FormInput
          id="password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setPassword(e.target.value)}
          label="Passwort"
          helperText={errorText}
          error={error}
          InputProps={{
            endAdornment: PasswordToggle,
          }}
        />
        <LoaderButton
          isLoading={authenticating}
          text="Anmelden"
          fullWidth
        />
        <Grid container>
          <Grid item xs>
            <RouterLink to="/forgotPassword" style={{ textDecoration: 'inherit' }}>
              Passwort vergessen?
            </RouterLink>
          </Grid>
          <Grid item>
            <RouterLink to="/register" style={{ textDecoration: 'inherit' }}>
              Registrieren
            </RouterLink>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
