import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

interface InfoDialogProps {
  open: boolean
  onClose: any
}

export default function InfoDialog({ open, onClose }: InfoDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Anzeige von Echtzeit-Messwerten</DialogTitle>
      <DialogContent style={{ display: 'inline-flex', flexDirection: 'column', alignContent: 'flex-start' }}>
        <Typography>
          Echzeit-Messwerte des der Messgeräte werden in aktuellen Softwareversionen auf Anfrage angezeigt.
        </Typography>
        <Typography>
          Mit Aktivieren der Schaltfläche &quot;Echtzeit-Messwerte anfordern&quot; werden für 5 Minuten die Daten der Messgeräte live aktualisiert.
        </Typography>
        <Typography>
          Durch erneutes Betätigen der Schaltfläche können Sie jederzeit Echtzeitwerte für weitere 5 Minuten abfragen.
        </Typography>
      </DialogContent>
      <DialogActions>
        {/* @ts-ignore */}
        <Button onClick={onClose}>Schließen</Button>
      </DialogActions>
    </Dialog>
  );
}
