import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  success: {
    color: green[500],
    fontSize: '0.9rem',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    display: 'inline',
  },
  error: {
    color: red[500],
    fontSize: '0.9rem',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    display: 'inline',
  },

}));

interface FeedbackProps {
  success?: boolean,
  successText?: string
  error?: boolean
  errorText?: string
  style?: object
}

export default function Feedback({
  success = false, successText = 'Daten erfolgreich geändert', error = false, errorText = 'Etwas ist schiefgelaufen', style = {}, ...props
}: FeedbackProps) {
  const classes = useStyles();
  if (success) {
    return (
      <Typography
        className={classes.success}
        style={style}
        {...props}
      >
        {successText}
      </Typography>
    );
  } if (error) {
    return (
      <Typography
        className={classes.error}
        style={style}
        {...props}
      >
        {errorText}
      </Typography>
    );
  }
  return null;
}
