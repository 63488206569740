import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  wrapperMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  wrapperFillWidth: {
    width: '100%',
    display: 'block!important',
  },
}));

interface LoaderButtonProps {
  isLoading: boolean
  disabled?: boolean
  text?: string
  type?: string
  fullWidth?: boolean
  noMargin?: boolean
  variant?: 'text' | 'outlined' | 'contained' | undefined
  // eslint-disable-next-line react/require-default-props
  onClick?: Function
}

export default function LoaderButton({
  isLoading, disabled = false, text = 'Absenden', type = 'submit', fullWidth = false, noMargin = false, onClick = undefined, variant = 'contained', ...props
}: LoaderButtonProps) {
  const classes = useStyles();
  const wrapperStyle = clsx(classes.wrapper, fullWidth && classes.wrapperFillWidth, !noMargin && classes.wrapperMargin);

  return (
    <div className={wrapperStyle}>
      {/* @ts-ignore */}
      <Button
        color="primary"
        disabled={disabled || isLoading}
        type={type}
        fullWidth={fullWidth}
        variant={variant}
        onClick={onClick}
        {...props}
      >
        {text}
      </Button>
      {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
}
