/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';

import { API } from '@aws-amplify/api';
import { PubSub } from '@aws-amplify/pubsub';

import { green } from '@material-ui/core/colors';
import { isIP } from 'is-ip';

import LoaderButton from '../LoaderButton';

const useStyles = makeStyles((theme) => ({
  inputs: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputGroupNoMargin: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  inputGroup: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  firstInput: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      width: '100%',
    },
  },
  textField: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      width: '100%',
    },
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: 16,
  },
  titleWrapper: {
    position: 'relative',
  },
  selectWrapper: {
    width: 200,
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: -12,
      right: 0,
    },
  },
  targetSelect: {
    width: 150,
  },
  success: {
    color: green[500],
    fontSize: '0.9rem',
  },

}));

function NetworkSettings() {
  const classes = useStyles();

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const [selected, setSelected] = useState(null);

  const [dhcp, setDhcp] = useState(1);
  const [dhcpChanged, setDhcpChanged] = useState(false);

  const [ipAddress, setIpAddress] = useState(details.IP);
  const [subnet, setSubnet] = useState(details.Subnetz);
  const [gateway, setGateway] = useState(details.Gateway);
  const [dns, setDns] = useState(details.DNS);

  async function handleReboot() {
    try {
      await PubSub.publish(
        `$aws/things/EMI-LOG_${selected.MAC}/shadow/update`,
        {
          state: {
            desired: {
              reboot: true,
            },
          },
        },
      );
    } catch (err) {
      console.log(err);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      setLoading(true);
      setSuccess(false);
      await API.post('emilog', 'updateSettings', {
        body: {
          serialNumber: selected.MAC,
          fields: {
            DHCP: dhcpChanged ? dhcp : undefined,
            IP: ipAddress || null,
            Subnetz: subnet || null,
            Gateway: gateway || null,
            DNS: dns || null,
          },
        },
      });
      setLoading(false);
      setSuccess(true);
      handleReboot();
    } catch (e) {
      setLoading(false);
    }
  }

  async function fetch() {
    try {
      const res = await API.get('emilog', 'data');
      const onlyEmilogs = res.filter((item) => item.Emicon !== true);
      setData(onlyEmilogs);
    } catch (e) {
      console.error(e);
    }
  }

  function validateNetworksettings() {
    if (dhcp) return true;
    if (!dhcp && isIP(ipAddress) && isIP(gateway) && isIP(dns) && isIP(subnet)) return true;
    return false;
  }

  function handleDhcpToggle(event) {
    setDhcpChanged(true);
    setDhcp(event.target.checked ? 1 : 0);
  }

  useEffect(() => {
    async function fetchDetails() {
      try {
        const res = await API.get('emilog', 'details', {
          queryStringParameters: {
            serialNumber: selected.MAC,
          },
        });
        setDetails(res);
      } catch (error) {
        console.error(error);
      }
    }
    if (selected !== null) {
      fetchDetails();
    }
  }, [selected]);

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    setDhcp(details.DHCP === undefined ? 1 : Number(details.DHCP));
    setIpAddress(details.IP);
    setSubnet(details.Subnetz);
    setDns(details.DNS);
    setGateway(details.Gateway);
  }, [details]);

  function validateNetworkAddress(string) {
    const cleaned = string.replace(/[^A-Fa-f0-9.:]/g, '');
    return cleaned;
  }

  const title = () => (
    <div className={classes.titleWrapper}>
      <Typography variant="h5">Netzwerkeinstellungen</Typography>
      <Autocomplete
        value={selected}
        onChange={(event, newValue) => {
          setSelected(newValue);
        }}
        size="small"
        id="combo-box-demo"
        options={data || []}
        getOptionLabel={(option) => (option.MAC ? option.MAC : '')}
        getOptionSelected={(option, value) => (value.value === option.MAC)}
        className={classes.selectWrapper}
        renderInput={(params) => <TextField {...params} label="EMI-LOG" variant="standard" />}
      />
    </div>
  );

  return (
    <Card className={classes.card}>
      <CardHeader
        title={title()}
      />
      <Divider />
      <CardContent className={classes.content}>
        <Grid container>
          <Grid item sm={12} lg={3} className={classes.selectElement}>
            <FormGroup>
              <FormControlLabel
                control={(
                  <Switch
                    color="primary"
                    // eslint-disable-next-line no-unneeded-ternary
                    checked={dhcp === 1}
                    onChange={handleDhcpToggle}
                    disabled={!selected}
                  />
                )}
                label="DHCP"
              />
            </FormGroup>
          </Grid>
          <Grid item sm={12} lg={9} className={classes.inputs}>
            <div className={classes.inputGroup}>
              <TextField
                type="text"
                className={classes.firstInput}
                size="small"
                label="IP-Adresse"
                value={ipAddress || ''}
                onChange={(e) => setIpAddress(validateNetworkAddress(e.target.value))}
                variant="outlined"
                disabled={!selected}
                required={!dhcp}
                error={!isIP(ipAddress) && ipAddress}
              />
              <TextField
                type="text"
                size="small"
                label="Subnetzmaske"
                className={classes.textField}
                value={subnet || ''}
                onChange={(e) => setSubnet(validateNetworkAddress(e.target.value))}
                variant="outlined"
                disabled={!selected}
                required={!dhcp}
                error={!isIP(subnet) && subnet}
              />
            </div>
            <div className={classes.inputGroupNoMargin}>
              <TextField
                type="text"
                className={classes.firstInput}
                size="small"
                label="Standardgateway"
                value={gateway || ''}
                onChange={(e) => setGateway(validateNetworkAddress(e.target.value))}
                variant="outlined"
                disabled={!selected}
                required={!dhcp}
                error={!isIP(gateway) && gateway}
              />
              <TextField
                type="text"
                size="small"
                label="DNS"
                className={classes.textField}
                value={dns || ''}
                onChange={(e) => setDns(validateNetworkAddress(e.target.value))}
                variant="outlined"
                disabled={!selected}
                required={!dhcp}
                error={!isIP(dns) && dns}
              />
            </div>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={classes.cardActions}>
        {success && (
          <Typography className={classes.success}>Daten erfolgreich geändert</Typography>
        )}
        <LoaderButton
          isLoading={loading}
          text="Absenden"
          fullWidth={false}
          onClick={handleSubmit}
          disabled={!selected || !validateNetworksettings()}
        />
      </CardActions>
    </Card>

  );
}

export default NetworkSettings;
