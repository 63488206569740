import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import { API } from '@aws-amplify/api';
import { Clear as ClearIcon } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import { red, green } from '@material-ui/core/colors';

import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(() => ({
  card: {
    minHeight: 256,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 2,
    overflowY: 'auto',
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: 16,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 4,
    '&:hover': {
      color: red[800],
    },
  },
  delete: {
    color: red[500],
  },
  input: {
    width: '50%',
  },
  checkIcon: {
    margin: 4,
    cursor: 'pointer',
    '&:hover': {
      color: green[600],
    },
  },
  clearIcon: {
    margin: 4,
    cursor: 'pointer',
    '&:hover': {
      color: red[500],
    },
  },
}));

function AddReceivers() {
  const classes = useStyles();

  const [receivers, setReceivers] = useState([]);
  const [loading, setLoading] = useState(true);

  const [newMail, setNewMail] = useState('');

  const [adding, setAdding] = useState(false);

  async function getReceivers() {
    const res = await API.get('emilog', 'getReceivers', {});
    setLoading(false);
    if (res === null || res === '') return;
    setReceivers(res);
  }

  async function handleDelete(email: string) {
    try {
      await API.post('emilog', 'deleteReceiver', { body: { email } });
      getReceivers();
    } catch (err) {
      console.error(err);
    }
  }

  async function handleAdd() {
    try {
      await API.post('emilog', 'addReceiver', { body: { email: newMail } });
      getReceivers();
      setAdding(false);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getReceivers();
  }, []);

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Benachrichtigungen"
        subheader="Zusätzliche E-Mail-Adressen für Benachrichtigungen"
      />
      <Divider />
      <CardContent className={classes.content}>
        {loading && (
          <>
            <Skeleton style={{ marginBottom: 10 }} variant="text" />
            <Skeleton style={{ marginBottom: 10 }} variant="text" />
            <Skeleton style={{ marginBottom: 10 }} variant="text" />
          </>
        )}
        {receivers?.map((element) => (
          <div key={element} className={classes.wrapper}>
            <Typography>{element}</Typography>
            <Tooltip title="Entfernen">
              <IconButton size="small" style={{ padding: 0 }} onClick={() => handleDelete(element)}>
                <ClearIcon className={classes.delete} />
              </IconButton>
            </Tooltip>
          </div>
        ))}
        {adding ? (
          <>
            <TextField
              className={classes.input}
              value={newMail}
              onChange={(e) => setNewMail(e.target.value)}
              type="email"
            />
            <Tooltip title="Absenden">
              <CheckIcon className={classes.checkIcon} fontSize="small" onClick={handleAdd} />
            </Tooltip>
            <Tooltip title="Abbrechen">
              <ClearIcon className={classes.clearIcon} fontSize="small" onClick={() => setAdding(false)} />
            </Tooltip>
          </>
        ) : (
          <Button color="primary" onClick={() => setAdding(true)}>+ Email-Adresse hinzufügen...</Button>
        )}
      </CardContent>
    </Card>

  );
}

export default AddReceivers;
