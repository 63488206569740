/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect } from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';

import PublicLayout from '../layouts/PublicLayout';
import AuthContext from '../context/AuthContext';

export default function PublicRoute({ component: RouteComponent, path, exact = true }: { component: React.FunctionComponent<RouteComponentProps>, path: string, exact: boolean }) {
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentUser?.loggedIn !== undefined) setLoading(false);
  }, [currentUser]);

  return (
    !loading
      ? (
        <Route
          path={path}
          exact={exact}
          render={(routeProps) => (!currentUser?.loggedIn ? (
            <PublicLayout>
              <RouteComponent {...routeProps} />
            </PublicLayout>
          ) : (
            <Redirect to="/" />
          )
          )}
        />
      ) : (
        <div />
      )
  );
}
