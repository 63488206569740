function convertTimestamp(timestamp?: number, showSeconds = false) {
  if (timestamp === undefined) return '-';
  const time = new Date(timestamp * 1000).toLocaleDateString('de-DE', {
    hour: '2-digit',
    minute: '2-digit',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    second: showSeconds ? '2-digit' : undefined,
  });
  return time;
}

export function convertTimer(timestamp: number) {
  const minutes = Math.floor(timestamp / 60000);
  let seconds: string | number = timestamp / 1000 - minutes * 60;
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return `${minutes}:${seconds}`;
}

export default convertTimestamp;
